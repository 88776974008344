import {neverland as $, html} from 'neverland';

const Footer = $(function(auth, theme) {

	return html`
		<footer>
			
			<div class="footer_left copyright">${ theme ? { html: theme.copyright.replace('{{YEAR}}', (new Date()).getFullYear()) } : ''}</div>
			
			<div class="support">
				<i class="fa fa-envelope"></i>&ensp;<a class="user-support-email" href="mailto:${theme ? theme.userSupportEmail : ''}">${theme ? theme.userSupportEmail : ''}</a>
			</div>
			
			<div class="support">
				<i class="fas fa-phone"></i>&ensp;<a class="user-support-phone" href="tel:${theme ? theme.userSupportPhone : ''}">${theme ? theme.userSupportPhone : ''}</a>
			</div>
			
			<img class="footer_logo" src="${auth ? (auth.themeDataLoaded ? `/static/img/logos-large/${theme.logo}` : '/static/img/transparent-placeholder.png') : '/static/img/transparent-placeholder.png'}" />
		</footer>
	`;

});

export default Footer;