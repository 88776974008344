import katex from "katex";

let math = function (tex, displayMode = false) {
	return katex.renderToString(String(tex), {
		throwOnError: false,
		displayMode: displayMode,
	});
};

let scatterplots = [
	{
		key: "healthy-mind",
		group: "student",
		hVar: "grade",
		surveys: [
			"student-resilience",
			"short-resilience",
			"relationships-and-no-bullying",
			"IPE-Mi7",
			"JGI_NatureWellbeingUpper",
			"learning-curve-wellbeing",
			"phillips-foundation",
		],
		showGemfinder: true,
		gemfinderOptions: {
			itemsToExclude: ["ph1", "ph2", "ph3", "ph4"],
			epithets: {
				graph: {
					A: "Healthy Mind",
					B: "Anxious or Depressed",
				},
				sentence: {
					A: "who are of healthy mind",
					B: "who are anxious or depressed",
				},
			},
			construct: "mental health",
		},
		shade: "#c0dad9",
		scales: ["GAD-2", "PHQ-2"],
		xMeasure: "Depressed",
		xMax: 6,
		yMeasure: "Anxious",
		yMax: 6,
		quarters: {
			leftbottom: "HEALTHY MIND",
			rightbottom: "DEPRESSED",
			lefttop: "ANXIOUS",
			righttop: "ANXIOUS & DEPRESSED",
		},
		title: "Healthy Mind Score",
		what_does_this_report_show: `
    <p>Anxiety and depression are commonly experienced by young people. On this scatterplot each student is represented with a single dot. The position of each dot indicates each student’s reported symptoms of either anxiety, depression, or both anxiety and depression, or the absence of these symptoms, suggesting a healthy mind. In other words, appropriate thresholds allow students to be categorised by whether they are:</p>
    <ul>
      <li><span class="boldcaps">ANXIOUS</span> (but not depressed),</li>
      <li><span class="boldcaps">Depressed</span> (but not anxious),</li>
      <li>both <span class="boldcaps">ANXIOUS &amp; DEPRESSED</span>, or</li>
      <li><span class="boldcaps">HEALTHY MIND</span> (neither anxious nor depressed).</li>
    </ul>
    <p>The percentage of your students who fall into each quadrant are shown on the graph.</p>
    <br />
    `,
		purpose: `
      <p>To provide a high-level overview that can facilitate target-setting and communication.</p>
    `,
		ask_yourself: `
      <ul>
        <li>What percentage of our students a likely to self-report symptoms of anxiety and depression?</li>
        <li>How does the prevalence of anxiety or depressive symptoms differ between genders and year levels?</li>
        <li>How has the percentage of students who experience anxiety and depression changed over time?</li>
        <li>What do you think is the reason for the variance in your data?</li>
      </ul>
    `,
		where_do_these_numbers_come_from: `
      <h5>Anxiety</h5><br />
          
      <h6>Raw Score</h6>
      <p>A student's score for anxiety is given by how they scored on the shortened General Anxiety Disorder scale, or GAD-2, which consists of the following questions.
      <ul class="questions">
        <li>Over the last 2 weeks, how often have you been bothered by the following problems?
          <ul>
            <li><a href="../ph1/"><span class="muted">ph1</span> - Feeling nervous, anxious, or on edge.</a></li>
            <li><a href="../ph2"><span class="muted">ph2</span> - Not being able to stop or control worrying.</a></li>
          </ul>
        </li>
      </ul>
      <p>Both questions used the same Likert response scale. For the purposes of computing the overall anxiety score, responses are encoded as follows:</p>
      <table>
        <tr><th>Response anchor</th><th>Numerical Encoding</th></tr>
        <tr><td>Not at all</td><td>0</td></tr>
        <tr><td>Several days</td><td>1</td></tr>
        <tr><td>More than half the days</td><td>2</td></tr>
        <tr><td>Nearly every day</td><td>3</td></tr>
      </table>
      <p>The GAD-2 score is then calculated by</p>
      <p>${math(`\\texttt{anxiety} := \\texttt{ph1} + \\texttt{ph2}`, true)}</p>
      <p>giving a score between ${math(0)} and ${math(6)}, inclusive.</p>

      <h6>Threshold</h6>
      <p>Literature on the GAD-2 (see the paper linked below) suggests that a threshold of ${math(
				3
			)} provides acceptable sensitivity, specificity and discriminant validity to be used as a measure of depression, anxiety and general stress. As such, we classify students as anxious if ${math(
			`\\texttt{anxiety}\\geq 3`
		)}.</p>

      <h6>Relevant Literature</h6>
      <ol class="literature">
        <li><a href="https://www.sciencedirect.com/science/article/pii/S0163834318303712?via%3Dihub">Staples, Lauren G., et al. "Psychometric properties and clinical utility of brief measures of depression, anxiety, and general distress: The PHQ-2, GAD-2, and K-6." <em>General hospital psychiatry</em> 56 (2019): 13-18.</a></li>
      </ol>

      <h5>Depressive Symptoms</h5><br/>

      <h6>Raw Score</h6>
      <p>The group into which a respondent is classified (either 'Depressed' or 'Not Depressed') is determined by their raw score on the shortened Patient Health Questionnaire scale, or PHQ-2, which consists of the following questions.
      <ul class="questions">
        <li>Over the last 2 weeks, how often have you been bothered by the following problems?
          <ul>
            <li><a href="../ph3/"><span class="muted">ph3</span> - Feeling down, depressed or hopeless.</a></li>
            <li><a href="../ph4/"><span class="muted">ph4</span> - Little interest or pleasure in doing things.</a></li>
          </ul>
        </li>
      </ul>
      <p>Both questions use the same Likert response scale. For the purposes of computing the overall depression score, responses are encoded as follows:</p>
      <table>
        <tr><th>Response anchor</th><th>Numerical Encoding</th></tr>
        <tr><td>Not at all</td><td>0</td></tr>
        <tr><td>Several days</td><td>1</td></tr>
        <tr><td>More than half the days</td><td>2</td></tr>
        <tr><td>Nearly every day</td><td>3</td></tr>
      </table>
      <p>The PHQ-2 score is then calculated by</p>
      <p>${math(`\\texttt{PHQ-2} := \\texttt{ph3} + \\texttt{ph4}`, true)}</p>
      <p>giving a score between ${math(0)} and ${math(6)}, inclusive.</p>

      <h6>Threshold</h6>
      <p>Literature on the PHQ-2 (see the articles linked below) suggests that a threshold of ${math(
				3
			)} provides acceptable sensitivity, specificity and discriminant validity to be used as a measure of depressive disorders. As such, we classify students as 'Depressed' if ${math(
			`\\texttt{PHQ-2}\\geq 3`
		)}, and 'Not Depressed' otherwise ${math(`\\texttt{PHQ-2}< 3`)}.</p>

      <h6>Relevant Literature</h6>
      <ol class="literature">
        <li><a href="https://www-cambridge-org.ezp.lib.unimelb.edu.au/core/services/aop-cambridge-core/content/view/A2083386E3287C1F982BCC82DCEA1CB6/S2056472400001265a.pdf/case_finding_and_screening_clinical_utility_of_the_patient_health_questionnaire_phq9_and_phq2_for_depression_in_primary_care_a_diagnostic_metaanalysis_of_40_studies.pdf">Mitchell, Alex J., et al. "Case finding and screening clinical utility of the Patient Health Questionnaire (PHQ-9 and PHQ-2) for depression in primary care: a diagnostic meta-analysis of 40 studies." <em>BJPsych open</em> 2.2 (2016): 127-138.</a></li>
        <li><a href="https://www.sciencedirect.com/science/article/abs/pii/S0022399904006361">Löwe, Bernd, Kurt Kroenke, and Kerstin Gräfe. "Detecting and monitoring depression with a two-item questionnaire (PHQ-2)." <em>Journal of psychosomatic research</em> 58.2 (2005): 163-171.</a></li>
        <li><a href="https://www.jstor.org/stable/3768417">Kroenke, Kurt, Robert L. Spitzer, and Janet BW Williams. "The Patient Health Questionnaire-2: validity of a two-item depression screener." <em>Medical care</em> (2003): 1284-1292.</a></li>
        <li><a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3100798/">Richardson, Laura P., et al. "Evaluation of the PHQ-2 as a brief screen for detecting major depression among adolescents." <em>Pediatrics</em> 125.5 (2010): e1097-e1103.</a></li>
        <li><a href="http://www.annfammed.org/content/8/4/348.long">Arroll, Bruce, et al. "Validation of PHQ-2 and PHQ-9 to screen for major depression in the primary care population." <em>The Annals of Family Medicine</em> 8.4 (2010): 348-353.</a></li>
        <li><a href="https://www.sciencedirect.com/science/article/pii/S0163834318303712?via%3Dihub">Staples, Lauren G., et al. "Psychometric properties and clinical utility of brief measures of depression, anxiety, and general distress: The PHQ-2, GAD-2, and K-6." <em>General hospital psychiatry</em> 56 (2019): 13-18.</a></li>
      </ol>
    `,
	},
	{
		key: "healthy-mind",
		group: "staff",
		hVar: "agegroup",
		surveys: ["school-staff-resilience"],
		shade: "#c0dad9",
		scales: ["GAD-2", "PHQ-2"],
		xMeasure: "Depressed",
		xMax: 6,
		yMeasure: "Anxious",
		yMax: 6,
		quarters: {
			leftbottom: "HEALTHY MIND",
			rightbottom: "DEPRESSED",
			lefttop: "ANXIOUS",
			righttop: "ANXIOUS & DEPRESSED",
		},
		title: "Anxiety and Depression",
		what_does_this_report_show: `
    <p>Anxiety and depression are commonly experienced by people. On this scatterplot each staff is represented with a single dot. The position of each dot indicates each staff’s reported symptoms of either anxiety, depression, or both anxiety and depression, or the absence of these symptoms, suggesting a healthy mind. In other words, appropriate thresholds allows staff to be categorised by whether they are:</p>
    <ul>
      <li><span class="boldcaps">ANXIOUS</span> (but not depressed),</li>
      <li><span class="boldcaps">Depressed</span> (but not anxious),</li>
      <li>both <span class="boldcaps">ANXIOUS &amp; DEPRESSED</span>, or</li>
      <li><span class="boldcaps">HEALTHY MIND</span> (neither anxious nor depressed).</li>
    </ul>
    <p>The percentage of your staffs who fall into each quadrant are shown on the graph.</p>
    <br />
    `,
		purpose: `
			<p>To allow you to track the prevalence of anxiety and depression among your staff.</p>
		`,
		ask_yourself: `
			<ul>
				<li>What percentage of our staff are likely to self-report symptoms of anxiety and depression?</li>
				<li>How does the prevalence of anxiety or depressive symptoms differ between age groups and years experience?</li>
				<li>How has the percentage of staff who experience anxiety and depression changed over time?</li>
				<li>What do you think is the reason for the variance in your data?</li>
			</ul>
		`,
		where_do_these_numbers_come_from: `
      <h5>Anxiety</h5><br />
          
      <h6>Raw Score</h6>
			<p>The group into which a respondent is classified (either 'Anxious' or 'Not Anxious') is determined by their raw score on the shortened General Anxiety Disorder scale, or GAD-2, which consists of the following questions.
			<ul class="questions">
				<li>Over the last 2 weeks, how often have you been bothered by the following problems?
					<ul>
						<li><a href="../ph1/"><span class="muted">ph1</span> - Feeling nervous, anxious, or on edge.</a></li>
						<li><a href="../ph2"><span class="muted">ph2</span> - Not being able to stop or control worrying.</a></li>
					</ul>
				</li>
			</ul>
			<p>Both questions use the same Likert response scale. For the purposes of computing the overall anxiety score, responses are encoded as follows:</p>
			<table>
				<tr><th>Response anchor</th><th>Numerical Encoding</th></tr>
				<tr><td>Not at all</td><td>0</td></tr>
				<tr><td>Several days</td><td>1</td></tr>
				<tr><td>More than half the days</td><td>2</td></tr>
				<tr><td>Nearly every day</td><td>3</td></tr>
			</table>
			<p>The GAD-2 score is then calculated by</p>
			<p>${math(`\\texttt{GAD-2} := \\texttt{ph1} + \\texttt{ph2}`, true)}</p>
			<p>giving a score between ${math(0)} and ${math(6)}, inclusive.</p>

			<h6>Threshold</h6>
			<p>Literature on the GAD-2 (see the articles linked below) suggests that a threshold of \\(3\\) provides acceptable sensitivity, specificity and discriminant validity to be used as a measure of anxiety disorders and general stress. As such, we classify respondents as 'Anxious' if ${math(
				`\\texttt{GAD-2}\\geq 3`
			)}, and 'Not Anxious' otherwise ${math(`\\texttt{GAD-2}< 3`)}.</p>

			<h6>Relevant Literature</h6>
			<ol class="literature">
				<li><a href="https://s3.amazonaws.com/academia.edu.documents/41377550/1-s2.0-S0163834315002406-main.pdf?response-content-disposition=inline%3B%20filename%3DScreening_for_anxiety_disorders_with_the.pdf&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIATUSBJ6BALQOGIJB4%2F20200428%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20200428T231700Z&X-Amz-Expires=3600&X-Amz-Security-Token=IQoJb3JpZ2luX2VjELf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIE1FK6RxfDigp%2BdfMasvjgaGuw2KEN2bJKKwVOWXzBADAiEA%2F0pyV1hXhf1MSDdYgvZm2NoDc6GiFuRf7lTJPiRsrsUqvQMI4P%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAAGgwyNTAzMTg4MTEyMDAiDBBFukGo%2BIYb9cKRFCqRA3lRioiCa1q7OYYucTWmC7exOFwGrmxd680wDCGMzLL0hL02SCVcpRVJbgWTGkqK0RM0Fnc3YLvp8Ql8kpeJncVpmIs8vLv4NkeoG38Mlvn%2Bj%2BCrdhjLN9QiF8voDoI3hcKHHwnQY%2F2UMS%2FZpSfsWgVUO%2B51ThUvVgP6fO3EcVMg6T7eizdielfcpeX5fWDaDPBClJQuM5ZDmN1SYIRAeX2FMpTauKyT9tx%2F30pqQLj2mM1qbWdjoDTCzKX7GSoC4URQeYEBgD7jxauDklexacAGSIs2tsEK9Ein9Qv%2F7O%2FVFqj79IJtP575UrsUe19wD6LeWKJ6f7VV7MtQiUTD%2FL9a7WUvobgWkDUHI2oNcHgM4oPfH08EgGeKlEStVSDddY4u3g2k6H9R2DR3muX8dQiALVcIWaN2b0wL5A%2B4zp5NnOfKNbXPuV9dPOnGelZElCoVfkPgaBWtckUOhA5KIEO6mXk1BHnStrIHN1umQnZhNQ9TsWi9cGI9%2BsvKPxw6SVLaoqZwilqQNStMnLjL56ePMNXqovUFOusBOu9PYb8I56ugePDCxA%2FVDtTEwt49JwdECHFVjQYpkimD9enu2e%2BSfUIznZlLpF%2FdYHJ65FNrGF32kN0Tif4zWgdnEAbjC26tzitvGweYczOy%2FvE0gRw7znBLKKf5vsEIDDr7euj9HhoWq9eE0gc9PosRboycFoojiEpAL0pRE3N8ZNTqEOJrOpNNeAQ1ZQQKvPp%2BX99te781K8HD%2FJ79KoeyhywFG3HpB%2BpefNg%2BmQ3d1VlazsDgeNk0IVO8lWlkjadV8B4NGSJbgCY6coZh1YQzRb6pqqomm%2Fumt9pUPJfpOCn18Ne2YfT1tA%3D%3D&X-Amz-SignedHeaders=host&X-Amz-Signature=44f443f777c4972aacf83379d1189f868468bfa3320e9fdcffed49b23a21842a">Plummer, Faye, et al. "Screening for anxiety disorders with the GAD-7 and GAD-2: a systematic review and diagnostic metaanalysis." <em>General hospital psychiatry</em> 39 (2016): 24-31.</a></li>
				<li><a href="https://www.sciencedirect.com/science/article/pii/S0163834318303712?via%3Dihub">Staples, Lauren G., et al. "Psychometric properties and clinical utility of brief measures of depression, anxiety, and general distress: The PHQ-2, GAD-2, and K-6." <em>General hospital psychiatry</em> 56 (2019): 13-18.</a></li>
			</ol>

      <h5>Depressive Symptoms</h5><br/>

      <h6>Raw Score</h6>
			<p>The group into which a respondent is classified (either 'Depressed' or 'Not Depressed') is determined by their raw score on the shortened Patient Health Questionnaire scale, or PHQ-2, which consists of the following questions.
			<ul class="questions">
				<li>Over the last 2 weeks, how often have you been bothered by the following problems?
					<ul>
						<li><a href="../ph3/"><span class="muted">ph3</span> - Feeling down, depressed or hopeless.</a></li>
						<li><a href="../ph4/"><span class="muted">ph4</span> - Little interest or pleasure in doing things.</a></li>
					</ul>
				</li>
			</ul>
			<p>Both questions use the same Likert response scale. For the purposes of computing the overall depression score, responses are encoded as follows:</p>
			<table>
				<tr><th>Response anchor</th><th>Numerical Encoding</th></tr>
				<tr><td>Not at all</td><td>0</td></tr>
				<tr><td>Several days</td><td>1</td></tr>
				<tr><td>More than half the days</td><td>2</td></tr>
				<tr><td>Nearly every day</td><td>3</td></tr>
			</table>
			<p>The PHQ-2 score is then calculated by</p>
			<p>${math(`\\texttt{PHQ-2} := \\texttt{ph3} + \\texttt{ph4}`, true)}</p>
			<p>giving a score between ${math(0)} and ${math(6)}, inclusive.</p>

			<h6>Threshold</h6>
			<p>Literature on the PHQ-2 (see the articles linked below) suggests that a threshold of ${math(
				3
			)} provides acceptable sensitivity, specificity and discriminant validity to be used as a measure of depressive disorders. As such, we classify respondents as 'Depressed' if ${math(
			`\\texttt{PHQ-2}\\geq 3`
		)}, and 'Not Depressed' otherwise ${math(`\\texttt{PHQ-2}< 3`)}.</p>

			<h6>Relevant Literature</h6>
			<ol class="literature">
				<li><a href="https://www-cambridge-org.ezp.lib.unimelb.edu.au/core/services/aop-cambridge-core/content/view/A2083386E3287C1F982BCC82DCEA1CB6/S2056472400001265a.pdf/case_finding_and_screening_clinical_utility_of_the_patient_health_questionnaire_phq9_and_phq2_for_depression_in_primary_care_a_diagnostic_metaanalysis_of_40_studies.pdf">Mitchell, Alex J., et al. "Case finding and screening clinical utility of the Patient Health Questionnaire (PHQ-9 and PHQ-2) for depression in primary care: a diagnostic meta-analysis of 40 studies." <em>BJPsych open</em> 2.2 (2016): 127-138.</a></li>
				<li><a href="https://www.sciencedirect.com/science/article/abs/pii/S0022399904006361">Löwe, Bernd, Kurt Kroenke, and Kerstin Gräfe. "Detecting and monitoring depression with a two-item questionnaire (PHQ-2)." <em>Journal of psychosomatic research</em> 58.2 (2005): 163-171.</a></li>
				<li><a href="https://www.jstor.org/stable/3768417">Kroenke, Kurt, Robert L. Spitzer, and Janet BW Williams. "The Patient Health Questionnaire-2: validity of a two-item depression screener." <em>Medical care</em> (2003): 1284-1292.</a></li>
				<li><a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3100798/">Richardson, Laura P., et al. "Evaluation of the PHQ-2 as a brief screen for detecting major depression among adolescents." <em>Pediatrics</em> 125.5 (2010): e1097-e1103.</a></li>
				<li><a href="http://www.annfammed.org/content/8/4/348.long">Arroll, Bruce, et al. "Validation of PHQ-2 and PHQ-9 to screen for major depression in the primary care population." <em>The Annals of Family Medicine</em> 8.4 (2010): 348-353.</a></li>
				<li><a href="https://www.sciencedirect.com/science/article/pii/S0163834318303712?via%3Dihub">Staples, Lauren G., et al. "Psychometric properties and clinical utility of brief measures of depression, anxiety, and general distress: The PHQ-2, GAD-2, and K-6." <em>General hospital psychiatry</em> 56 (2019): 13-18.</a></li>
			</ol>
    `,
	},
	{
		key: "healthy-mind",
		group: "employee",
		hVar: "agegroup",
		surveys: ["workplace-resilience"],
		shade: "#c0dad9",
		scales: ["GAD-2", "PHQ-2"],
		xMeasure: "Depressed",
		xMax: 6,
		yMeasure: "Anxious",
		yMax: 6,
		quarters: {
			leftbottom: "HEALTHY MIND",
			rightbottom: "DEPRESSED",
			lefttop: "ANXIOUS",
			righttop: "ANXIOUS & DEPRESSED",
		},
		title: "Anxiety and Depression",
		what_does_this_report_show: `
    <p>Anxiety and depression are commonly experienced by people. On this scatterplot each employee is represented with a single dot. The position of each dot indicates each employee’s reported symptoms of either anxiety, depression, or both anxiety and depression, or the absence of these symptoms, suggesting a healthy mind. In other words, appropriate thresholds allows employee to be categorised by whether they are:</p>
    <ul>
      <li><span class="boldcaps">ANXIOUS</span> (but not depressed),</li>
      <li><span class="boldcaps">Depressed</span> (but not anxious),</li>
      <li>both <span class="boldcaps">ANXIOUS &amp; DEPRESSED</span>, or</li>
      <li><span class="boldcaps">HEALTHY MIND</span> (neither anxious nor depressed).</li>
    </ul>
    <p>The percentage of your employees who fall into each quadrant are shown on the graph.</p>
    <br />
    `,
		purpose: `
			<p>To allow you to track the prevalence of anxiety and depression among your employee.</p>
		`,
		ask_yourself: `
			<ul>
				<li>What percentage of our employee are likely to self-report symptoms of anxiety and depression?</li>
				<li>How does the prevalence of anxiety or depressive symptoms differ between age groups and years experience?</li>
				<li>How has the percentage of employee who experience anxiety and depression changed over time?</li>
				<li>What do you think is the reason for the variance in your data?</li>
			</ul>
		`,
		where_do_these_numbers_come_from: `
      <h5>Anxiety</h5><br />
          
      <h6>Raw Score</h6>
			<p>The group into which a respondent is classified (either 'Anxious' or 'Not Anxious') is determined by their raw score on the shortened General Anxiety Disorder scale, or GAD-2, which consists of the following questions.
			<ul class="questions">
				<li>Over the last 2 weeks, how often have you been bothered by the following problems?
					<ul>
						<li><a href="../ph1/"><span class="muted">ph1</span> - Feeling nervous, anxious, or on edge.</a></li>
						<li><a href="../ph2"><span class="muted">ph2</span> - Not being able to stop or control worrying.</a></li>
					</ul>
				</li>
			</ul>
			<p>Both questions use the same Likert response scale. For the purposes of computing the overall anxiety score, responses are encoded as follows:</p>
			<table>
				<tr><th>Response anchor</th><th>Numerical Encoding</th></tr>
				<tr><td>Not at all</td><td>0</td></tr>
				<tr><td>Several days</td><td>1</td></tr>
				<tr><td>More than half the days</td><td>2</td></tr>
				<tr><td>Nearly every day</td><td>3</td></tr>
			</table>
			<p>The GAD-2 score is then calculated by</p>
			<p>${math(`\\texttt{GAD-2} := \\texttt{ph1} + \\texttt{ph2}`, true)}</p>
			<p>giving a score between ${math(0)} and ${math(6)}, inclusive.</p>

			<h6>Threshold</h6>
			<p>Literature on the GAD-2 (see the articles linked below) suggests that a threshold of \\(3\\) provides acceptable sensitivity, specificity and discriminant validity to be used as a measure of anxiety disorders and general stress. As such, we classify respondents as 'Anxious' if ${math(
				`\\texttt{GAD-2}\\geq 3`
			)}, and 'Not Anxious' otherwise ${math(`\\texttt{GAD-2}< 3`)}.</p>

			<h6>Relevant Literature</h6>
			<ol class="literature">
				<li><a href="https://s3.amazonaws.com/academia.edu.documents/41377550/1-s2.0-S0163834315002406-main.pdf?response-content-disposition=inline%3B%20filename%3DScreening_for_anxiety_disorders_with_the.pdf&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIATUSBJ6BALQOGIJB4%2F20200428%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20200428T231700Z&X-Amz-Expires=3600&X-Amz-Security-Token=IQoJb3JpZ2luX2VjELf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIE1FK6RxfDigp%2BdfMasvjgaGuw2KEN2bJKKwVOWXzBADAiEA%2F0pyV1hXhf1MSDdYgvZm2NoDc6GiFuRf7lTJPiRsrsUqvQMI4P%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAAGgwyNTAzMTg4MTEyMDAiDBBFukGo%2BIYb9cKRFCqRA3lRioiCa1q7OYYucTWmC7exOFwGrmxd680wDCGMzLL0hL02SCVcpRVJbgWTGkqK0RM0Fnc3YLvp8Ql8kpeJncVpmIs8vLv4NkeoG38Mlvn%2Bj%2BCrdhjLN9QiF8voDoI3hcKHHwnQY%2F2UMS%2FZpSfsWgVUO%2B51ThUvVgP6fO3EcVMg6T7eizdielfcpeX5fWDaDPBClJQuM5ZDmN1SYIRAeX2FMpTauKyT9tx%2F30pqQLj2mM1qbWdjoDTCzKX7GSoC4URQeYEBgD7jxauDklexacAGSIs2tsEK9Ein9Qv%2F7O%2FVFqj79IJtP575UrsUe19wD6LeWKJ6f7VV7MtQiUTD%2FL9a7WUvobgWkDUHI2oNcHgM4oPfH08EgGeKlEStVSDddY4u3g2k6H9R2DR3muX8dQiALVcIWaN2b0wL5A%2B4zp5NnOfKNbXPuV9dPOnGelZElCoVfkPgaBWtckUOhA5KIEO6mXk1BHnStrIHN1umQnZhNQ9TsWi9cGI9%2BsvKPxw6SVLaoqZwilqQNStMnLjL56ePMNXqovUFOusBOu9PYb8I56ugePDCxA%2FVDtTEwt49JwdECHFVjQYpkimD9enu2e%2BSfUIznZlLpF%2FdYHJ65FNrGF32kN0Tif4zWgdnEAbjC26tzitvGweYczOy%2FvE0gRw7znBLKKf5vsEIDDr7euj9HhoWq9eE0gc9PosRboycFoojiEpAL0pRE3N8ZNTqEOJrOpNNeAQ1ZQQKvPp%2BX99te781K8HD%2FJ79KoeyhywFG3HpB%2BpefNg%2BmQ3d1VlazsDgeNk0IVO8lWlkjadV8B4NGSJbgCY6coZh1YQzRb6pqqomm%2Fumt9pUPJfpOCn18Ne2YfT1tA%3D%3D&X-Amz-SignedHeaders=host&X-Amz-Signature=44f443f777c4972aacf83379d1189f868468bfa3320e9fdcffed49b23a21842a">Plummer, Faye, et al. "Screening for anxiety disorders with the GAD-7 and GAD-2: a systematic review and diagnostic metaanalysis." <em>General hospital psychiatry</em> 39 (2016): 24-31.</a></li>
				<li><a href="https://www.sciencedirect.com/science/article/pii/S0163834318303712?via%3Dihub">Staples, Lauren G., et al. "Psychometric properties and clinical utility of brief measures of depression, anxiety, and general distress: The PHQ-2, GAD-2, and K-6." <em>General hospital psychiatry</em> 56 (2019): 13-18.</a></li>
			</ol>

      <h5>Depressive Symptoms</h5><br/>

      <h6>Raw Score</h6>
			<p>The group into which a respondent is classified (either 'Depressed' or 'Not Depressed') is determined by their raw score on the shortened Patient Health Questionnaire scale, or PHQ-2, which consists of the following questions.
			<ul class="questions">
				<li>Over the last 2 weeks, how often have you been bothered by the following problems?
					<ul>
						<li><a href="../ph3/"><span class="muted">ph3</span> - Feeling down, depressed or hopeless.</a></li>
						<li><a href="../ph4/"><span class="muted">ph4</span> - Little interest or pleasure in doing things.</a></li>
					</ul>
				</li>
			</ul>
			<p>Both questions use the same Likert response scale. For the purposes of computing the overall depression score, responses are encoded as follows:</p>
			<table>
				<tr><th>Response anchor</th><th>Numerical Encoding</th></tr>
				<tr><td>Not at all</td><td>0</td></tr>
				<tr><td>Several days</td><td>1</td></tr>
				<tr><td>More than half the days</td><td>2</td></tr>
				<tr><td>Nearly every day</td><td>3</td></tr>
			</table>
			<p>The PHQ-2 score is then calculated by</p>
			<p>${math(`\\texttt{PHQ-2} := \\texttt{ph3} + \\texttt{ph4}`, true)}</p>
			<p>giving a score between ${math(0)} and ${math(6)}, inclusive.</p>

			<h6>Threshold</h6>
			<p>Literature on the PHQ-2 (see the articles linked below) suggests that a threshold of ${math(
				3
			)} provides acceptable sensitivity, specificity and discriminant validity to be used as a measure of depressive disorders. As such, we classify respondents as 'Depressed' if ${math(
			`\\texttt{PHQ-2}\\geq 3`
		)}, and 'Not Depressed' otherwise ${math(`\\texttt{PHQ-2}< 3`)}.</p>

			<h6>Relevant Literature</h6>
			<ol class="literature">
				<li><a href="https://www-cambridge-org.ezp.lib.unimelb.edu.au/core/services/aop-cambridge-core/content/view/A2083386E3287C1F982BCC82DCEA1CB6/S2056472400001265a.pdf/case_finding_and_screening_clinical_utility_of_the_patient_health_questionnaire_phq9_and_phq2_for_depression_in_primary_care_a_diagnostic_metaanalysis_of_40_studies.pdf">Mitchell, Alex J., et al. "Case finding and screening clinical utility of the Patient Health Questionnaire (PHQ-9 and PHQ-2) for depression in primary care: a diagnostic meta-analysis of 40 studies." <em>BJPsych open</em> 2.2 (2016): 127-138.</a></li>
				<li><a href="https://www.sciencedirect.com/science/article/abs/pii/S0022399904006361">Löwe, Bernd, Kurt Kroenke, and Kerstin Gräfe. "Detecting and monitoring depression with a two-item questionnaire (PHQ-2)." <em>Journal of psychosomatic research</em> 58.2 (2005): 163-171.</a></li>
				<li><a href="https://www.jstor.org/stable/3768417">Kroenke, Kurt, Robert L. Spitzer, and Janet BW Williams. "The Patient Health Questionnaire-2: validity of a two-item depression screener." <em>Medical care</em> (2003): 1284-1292.</a></li>
				<li><a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3100798/">Richardson, Laura P., et al. "Evaluation of the PHQ-2 as a brief screen for detecting major depression among adolescents." <em>Pediatrics</em> 125.5 (2010): e1097-e1103.</a></li>
				<li><a href="http://www.annfammed.org/content/8/4/348.long">Arroll, Bruce, et al. "Validation of PHQ-2 and PHQ-9 to screen for major depression in the primary care population." <em>The Annals of Family Medicine</em> 8.4 (2010): 348-353.</a></li>
				<li><a href="https://www.sciencedirect.com/science/article/pii/S0163834318303712?via%3Dihub">Staples, Lauren G., et al. "Psychometric properties and clinical utility of brief measures of depression, anxiety, and general distress: The PHQ-2, GAD-2, and K-6." <em>General hospital psychiatry</em> 56 (2019): 13-18.</a></li>
			</ol>
    `,
	},
	{
		key: "healthy-mind",
		group: "parent",
		hVar: "grade",
		surveys: ["parent-resilience"],
		shade: "#c0dad9",
		scales: ["GAD-2", "PHQ-2"],
		xMeasure: "Depressed",
		xMax: 6,
		yMeasure: "Anxious",
		yMax: 6,
		quarters: {
			leftbottom: "HEALTHY MIND",
			rightbottom: "DEPRESSED",
			lefttop: "ANXIOUS",
			righttop: "ANXIOUS & DEPRESSED",
		},
		title: "Anxiety and Depression",
		what_does_this_report_show: `
    <p>Anxiety and depression are commonly experienced by people. On this scatterplot each parent/caregiver is represented with a single dot. The position of each dot indicates each parent's/caregiver's reported symptoms of either anxiety, depression, or both anxiety and depression, or the absence of these symptoms, suggesting a healthy mind. In other words, appropriate thresholds allows parents/caregivers to be categorised by whether they are:</p>
    <ul>
      <li><span class="boldcaps">ANXIOUS</span> (but not depressed),</li>
      <li><span class="boldcaps">Depressed</span> (but not anxious),</li>
      <li>both <span class="boldcaps">ANXIOUS &amp; DEPRESSED</span>, or</li>
      <li><span class="boldcaps">HEALTHY MIND</span> (neither anxious nor depressed).</li>
    </ul>
    <p>The percentage of your parents/caregivers who fall into each quadrant are shown on the graph.</p>
    <br />
    `,
		purpose: `
			<p>To allow you to track the prevalence of anxiety and depression among your student's parents/caregivers.</p>
		`,
		ask_yourself: `
			<ul>
				<li>What percentage of our parents/caregivers are likely to self-report symptoms of anxiety and depression?</li>
				<li>How does the prevalence of anxiety or depressive symptoms differ between year levels?</li>
				<li>How has the percentage of staff who experience anxiety and depression changed over time?</li>
				<li>What do you think is the reason for the variance in your data?</li>
			</ul>
		`,
		where_do_these_numbers_come_from: `
      <h5>Anxiety</h5><br />
          
      <h6>Raw Score</h6>
			<p>The group into which a respondent is classified (either 'Anxious' or 'Not Anxious') is determined by their raw score on the shortened General Anxiety Disorder scale, or GAD-2, which consists of the following questions.
			<ul class="questions">
				<li>Over the last 2 weeks, how often have you been bothered by the following problems?
					<ul>
						<li><a href="../ph1/"><span class="muted">ph1</span> - Feeling nervous, anxious, or on edge.</a></li>
						<li><a href="../ph2"><span class="muted">ph2</span> - Not being able to stop or control worrying.</a></li>
					</ul>
				</li>
			</ul>
			<p>Both questions use the same Likert response scale. For the purposes of computing the overall anxiety score, responses are encoded as follows:</p>
			<table>
				<tr><th>Response anchor</th><th>Numerical Encoding</th></tr>
				<tr><td>Not at all</td><td>0</td></tr>
				<tr><td>Several days</td><td>1</td></tr>
				<tr><td>More than half the days</td><td>2</td></tr>
				<tr><td>Nearly every day</td><td>3</td></tr>
			</table>
			<p>The GAD-2 score is then calculated by</p>
			<p>${math(`\\texttt{GAD-2} := \\texttt{ph1} + \\texttt{ph2}`, true)}</p>
			<p>giving a score between ${math(0)} and ${math(6)}, inclusive.</p>

			<h6>Threshold</h6>
			<p>Literature on the GAD-2 (see the articles linked below) suggests that a threshold of \\(3\\) provides acceptable sensitivity, specificity and discriminant validity to be used as a measure of anxiety disorders and general stress. As such, we classify respondents as 'Anxious' if ${math(
				`\\texttt{GAD-2}\\geq 3`
			)}, and 'Not Anxious' otherwise ${math(`\\texttt{GAD-2}< 3`)}.</p>

			<h6>Relevant Literature</h6>
			<ol class="literature">
				<li><a href="https://s3.amazonaws.com/academia.edu.documents/41377550/1-s2.0-S0163834315002406-main.pdf?response-content-disposition=inline%3B%20filename%3DScreening_for_anxiety_disorders_with_the.pdf&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIATUSBJ6BALQOGIJB4%2F20200428%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20200428T231700Z&X-Amz-Expires=3600&X-Amz-Security-Token=IQoJb3JpZ2luX2VjELf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIE1FK6RxfDigp%2BdfMasvjgaGuw2KEN2bJKKwVOWXzBADAiEA%2F0pyV1hXhf1MSDdYgvZm2NoDc6GiFuRf7lTJPiRsrsUqvQMI4P%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAAGgwyNTAzMTg4MTEyMDAiDBBFukGo%2BIYb9cKRFCqRA3lRioiCa1q7OYYucTWmC7exOFwGrmxd680wDCGMzLL0hL02SCVcpRVJbgWTGkqK0RM0Fnc3YLvp8Ql8kpeJncVpmIs8vLv4NkeoG38Mlvn%2Bj%2BCrdhjLN9QiF8voDoI3hcKHHwnQY%2F2UMS%2FZpSfsWgVUO%2B51ThUvVgP6fO3EcVMg6T7eizdielfcpeX5fWDaDPBClJQuM5ZDmN1SYIRAeX2FMpTauKyT9tx%2F30pqQLj2mM1qbWdjoDTCzKX7GSoC4URQeYEBgD7jxauDklexacAGSIs2tsEK9Ein9Qv%2F7O%2FVFqj79IJtP575UrsUe19wD6LeWKJ6f7VV7MtQiUTD%2FL9a7WUvobgWkDUHI2oNcHgM4oPfH08EgGeKlEStVSDddY4u3g2k6H9R2DR3muX8dQiALVcIWaN2b0wL5A%2B4zp5NnOfKNbXPuV9dPOnGelZElCoVfkPgaBWtckUOhA5KIEO6mXk1BHnStrIHN1umQnZhNQ9TsWi9cGI9%2BsvKPxw6SVLaoqZwilqQNStMnLjL56ePMNXqovUFOusBOu9PYb8I56ugePDCxA%2FVDtTEwt49JwdECHFVjQYpkimD9enu2e%2BSfUIznZlLpF%2FdYHJ65FNrGF32kN0Tif4zWgdnEAbjC26tzitvGweYczOy%2FvE0gRw7znBLKKf5vsEIDDr7euj9HhoWq9eE0gc9PosRboycFoojiEpAL0pRE3N8ZNTqEOJrOpNNeAQ1ZQQKvPp%2BX99te781K8HD%2FJ79KoeyhywFG3HpB%2BpefNg%2BmQ3d1VlazsDgeNk0IVO8lWlkjadV8B4NGSJbgCY6coZh1YQzRb6pqqomm%2Fumt9pUPJfpOCn18Ne2YfT1tA%3D%3D&X-Amz-SignedHeaders=host&X-Amz-Signature=44f443f777c4972aacf83379d1189f868468bfa3320e9fdcffed49b23a21842a">Plummer, Faye, et al. "Screening for anxiety disorders with the GAD-7 and GAD-2: a systematic review and diagnostic metaanalysis." <em>General hospital psychiatry</em> 39 (2016): 24-31.</a></li>
				<li><a href="https://www.sciencedirect.com/science/article/pii/S0163834318303712?via%3Dihub">Staples, Lauren G., et al. "Psychometric properties and clinical utility of brief measures of depression, anxiety, and general distress: The PHQ-2, GAD-2, and K-6." <em>General hospital psychiatry</em> 56 (2019): 13-18.</a></li>
			</ol>

      <h5>Depressive Symptoms</h5><br/>

      <h6>Raw Score</h6>
			<p>The group into which a respondent is classified (either 'Depressed' or 'Not Depressed') is determined by their raw score on the shortened Patient Health Questionnaire scale, or PHQ-2, which consists of the following questions.
			<ul class="questions">
				<li>Over the last 2 weeks, how often have you been bothered by the following problems?
					<ul>
						<li><a href="../ph3/"><span class="muted">ph3</span> - Feeling down, depressed or hopeless.</a></li>
						<li><a href="../ph4/"><span class="muted">ph4</span> - Little interest or pleasure in doing things.</a></li>
					</ul>
				</li>
			</ul>
			<p>Both questions use the same Likert response scale. For the purposes of computing the overall depression score, responses are encoded as follows:</p>
			<table>
				<tr><th>Response anchor</th><th>Numerical Encoding</th></tr>
				<tr><td>Not at all</td><td>0</td></tr>
				<tr><td>Several days</td><td>1</td></tr>
				<tr><td>More than half the days</td><td>2</td></tr>
				<tr><td>Nearly every day</td><td>3</td></tr>
			</table>
			<p>The PHQ-2 score is then calculated by</p>
			<p>${math(`\\texttt{PHQ-2} := \\texttt{ph3} + \\texttt{ph4}`, true)}</p>
			<p>giving a score between ${math(0)} and ${math(6)}, inclusive.</p>

			<h6>Threshold</h6>
			<p>Literature on the PHQ-2 (see the articles linked below) suggests that a threshold of ${math(
				3
			)} provides acceptable sensitivity, specificity and discriminant validity to be used as a measure of depressive disorders. As such, we classify respondents as 'Depressed' if ${math(
			`\\texttt{PHQ-2}\\geq 3`
		)}, and 'Not Depressed' otherwise ${math(`\\texttt{PHQ-2}< 3`)}.</p>

			<h6>Relevant Literature</h6>
			<ol class="literature">
				<li><a href="https://www-cambridge-org.ezp.lib.unimelb.edu.au/core/services/aop-cambridge-core/content/view/A2083386E3287C1F982BCC82DCEA1CB6/S2056472400001265a.pdf/case_finding_and_screening_clinical_utility_of_the_patient_health_questionnaire_phq9_and_phq2_for_depression_in_primary_care_a_diagnostic_metaanalysis_of_40_studies.pdf">Mitchell, Alex J., et al. "Case finding and screening clinical utility of the Patient Health Questionnaire (PHQ-9 and PHQ-2) for depression in primary care: a diagnostic meta-analysis of 40 studies." <em>BJPsych open</em> 2.2 (2016): 127-138.</a></li>
				<li><a href="https://www.sciencedirect.com/science/article/abs/pii/S0022399904006361">Löwe, Bernd, Kurt Kroenke, and Kerstin Gräfe. "Detecting and monitoring depression with a two-item questionnaire (PHQ-2)." <em>Journal of psychosomatic research</em> 58.2 (2005): 163-171.</a></li>
				<li><a href="https://www.jstor.org/stable/3768417">Kroenke, Kurt, Robert L. Spitzer, and Janet BW Williams. "The Patient Health Questionnaire-2: validity of a two-item depression screener." <em>Medical care</em> (2003): 1284-1292.</a></li>
				<li><a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3100798/">Richardson, Laura P., et al. "Evaluation of the PHQ-2 as a brief screen for detecting major depression among adolescents." <em>Pediatrics</em> 125.5 (2010): e1097-e1103.</a></li>
				<li><a href="http://www.annfammed.org/content/8/4/348.long">Arroll, Bruce, et al. "Validation of PHQ-2 and PHQ-9 to screen for major depression in the primary care population." <em>The Annals of Family Medicine</em> 8.4 (2010): 348-353.</a></li>
				<li><a href="https://www.sciencedirect.com/science/article/pii/S0163834318303712?via%3Dihub">Staples, Lauren G., et al. "Psychometric properties and clinical utility of brief measures of depression, anxiety, and general distress: The PHQ-2, GAD-2, and K-6." <em>General hospital psychiatry</em> 56 (2019): 13-18.</a></li>
			</ol>
    `,
	},
	{
		key: "ready-to-learn",
		group: "student",
		hVar: "grade",
		surveys: [
			"student-resilience",
			"short-resilience",
			"relationships-and-no-bullying",
		],
		showGemfinder: true,
		gemfinderOptions: {
			itemsToExclude: ["ph1", "ph2", "cop1", "cop2", "cop3", "cop4"],
			epithets: {
				graph: {
					A: "Ready to Learn",
					B: "Anxious or Disengaged",
				},
				sentence: {
					A: "who are ready to learn",
					B: "who are anxious or disengaged",
				},
			},
			construct: "readiness to learn",
		},
		shade: "#fedb4a",
		scales: ["GAD-2", "CSI-4"],
		xMeasure: "Disengaged (from problem solving)",
		xMax: 8,
		yMeasure: "Anxious",
		yMax: 6,
		quarters: {
			leftbottom: "READY TO LEARN",
			rightbottom: "DISENGAGED",
			lefttop: "ANXIOUS",
			righttop: "ANXIOUS & DISENGAGED",
		},
		title: "Ready to Learn Score",
		what_does_this_report_show: `
      <p>The many ways students respond negatively to challenging situations fall into two broad classes: anxiety and disengagement. On this scatterplot each student is represented with a single dot. The position of each dot indicates each student’s reported symptoms of either anxiety, disengagement from problem solving, or both anxiety and disengagement from problem solving, or the absence of these symptoms, suggesting a positive learners mindset. In other words, appropriate thresholds allow students to be categorised by whether they are</p>
      <ul>
        <li><span class="boldcaps">ANXIOUS</span> (but not disengaged),</li>
        <li><span class="boldcaps">DISENGAGED</span> from problem solving (but not anxious),</li>
        <li>both <span class="boldcaps">ANXIOUS &amp; DISENGAGED</span> from problem solving, or</li>
        <li><span class="boldcaps">READY TO LEARN</span> (neither anxious nor disengaged from problem solving).</li>
      </ul>
      <p>The percentage of your students who fall into each quadrant are shown on the graph.</p>
      <br />
      <p><span class="boldcaps">PLEASE NOTE:</span> The measure of disengagement displayed here is a different kind of engagement to that measured in the Learning Engagement reporting area. The disengagement presented in this scatterplot is a measure of how likely a student is, in general, to use disengaged coping strategies when they have a problem. In contrast, the Learning Engagement construct is a measure of the degree to which students report positive attitudes and habits towards workplace and learning</p>
    `,
		purpose: `
      <p>To provide a high-level overview that can facilitate target-setting and communication.</p>
    `,
		ask_yourself: `
    <ul>
      <li>What percentage of our students have an anxious or disengaged mindset?</li>
      <li>How do levels of anxiety and disengagement differ between younger and older students?</li>
      <li>How has the percentage of students who are ready to learn changed over time?</li>
    </ul>
    `,
		where_do_these_numbers_come_from: `
    <h5>Anxiety</h5><br />
        
    <h6>Raw Score</h6>
    <p>A student's score for anxiety is given by how they scored on the shortened General Anxiety Disorder scale, or GAD-2, which consists of the following questions.
    <ul class="questions">
      <li>Over the last 2 weeks, how often have you been bothered by the following problems?
        <ul>
          <li><a href="../ph1/"><span class="muted">ph1</span> - Feeling nervous, anxious, or on edge.</a></li>
          <li><a href="../ph2"><span class="muted">ph2</span> - Not being able to stop or control worrying.</a></li>
        </ul>
      </li>
    </ul>
    <p>Both questions used the same Likert response scale. For the purposes of computing the overall anxiety score, responses are encoded as follows:</p>
    <table>
      <tr><th>Response anchor</th><th>Numerical Encoding</th></tr>
      <tr><td>Not at all</td><td>0</td></tr>
      <tr><td>Several days</td><td>1</td></tr>
      <tr><td>More than half the days</td><td>2</td></tr>
      <tr><td>Nearly every day</td><td>3</td></tr>
    </table>
    <p>The GAD-2 score is then calculated by</p>
    <p>${math(`\\texttt{anxiety} := \\texttt{ph1} + \\texttt{ph2}`, true)}</p>
    <p>giving a score between ${math(0)} and ${math(6)}, inclusive.</p>

    <h6>Threshold</h6>
    <p>Literature on the GAD-2 (see the paper linked below) suggests that a threshold of ${math(
			3
		)} provides acceptable sensitivity, specificity and discriminant validity to be used as a measure of depression, anxiety and general stress. As such, we classify students as anxious if ${math(
			`\\texttt{anxiety}\\geq 3`
		)}.</p>

    <h6>Relevant Literature</h6>
    <ol class="literature">
      <li><a href="https://www.sciencedirect.com/science/article/pii/S0163834318303712?via%3Dihub">Staples, Lauren G., et al. "Psychometric properties and clinical utility of brief measures of depression, anxiety, and general distress: The PHQ-2, GAD-2, and K-6." <em>General hospital psychiatry</em> 56 (2019): 13-18.</a></li>
    </ol>

    <h5>Disengagement</h5><br />
    
    <h6>Raw Score</h6>
    <p>A student's score for disengagement is given by how they scored on an adapted version of the Coping Strategies Index, or CSI, which consists of the following questions.
    <ul class="questions">
			<li><a href="../cop1/"><span class="muted">cop1</span> - When I have a problem, I avoid doing anything about it.</a></li>
			<li><a href="../cop2/"><span class="muted">cop2</span> - When I have a problem, I just wish it would go away.</a></li>
			<li><a href="../cop3/"><span class="muted">cop3</span> - When bad things happen, I usually blame myself.</a></li>
			<li><a href="../cop4/"><span class="muted">cop4</span> - When bad things happen, I talk to others about my problem.</a></li>
		</ul>
		<p>All questions use the same Likert response scale. For the purposes of computing the overall disengagement score, responses are encoded as follows:</p>
		<table>
			<tr><th>Response anchor*</th><th>Numerical Encoding</th></tr>
			<tr><td>Never or Rarely</td><td>0</td></tr>
			<tr><td>Sometimes</td><td>0</td></tr>
			<tr><td>Often</td><td>1</td></tr>
			<tr><td>Almost Always</td><td>2</td></tr>
		</table>
		<p><em>* Note that for cop4, the response anchors are reversed.</em></p>
    <p>The adapted CSI score is then calculated by</p>
    <p>${math(
			`\\texttt{disengagement} := \\texttt{cop1} + \\texttt{cop2} + \\texttt{cop3} + \\texttt{cop4}`,
			true
		)}</p>
    <p>giving a score between ${math(0)} and ${math(8)}, inclusive.</p>

    <h6>Threshold</h6>
    <p>Literature on CSI is sparser than for the GAD-2, and there is not a threshold that is widely agreed upon. We use a threshold of ${math(
			4
		)}, classifying students as disengaged if ${math(
			`\\texttt{disengagement}\\geq 4`
		)}.</p>
    <p>Intuitively, this means that a student would need to be using at least either</p>
    <ul>
      <li>two of the disengaged coping strategies 'Always or Almost Always', or</li>
      <li>two of the disengaged coping strategies 'Often' and one 'Always or Almost Always', or</li>
      <li>all four of the disengaged coping strategies 'Often',</li>
    </ul>
    <p>in order to be classified as disengaged.</p>
  </div>
    `,
	},
	{
		key: "resilience",
		group: "student",
		hVar: "grade",
		surveys: [
			"student-resilience",
			"short-resilience",
			"relationships-and-no-bullying",
			"phillips-foundation"
		],
		showGemfinder: true,
		gemfinderOptions: {
			itemsToExclude: ["chs1", "chs2", "chs3", "chs4", "chs5", "chs6", "cantril"],
			epithets: {
				graph: {
					A: "Hopeful and satisfied",
					B: "Low hope or satisfaction",
				},
				sentence: {
					A: "who are hopeful and satisfied",
					B: "who have low hope or life satisfaction",
				},
			},
			construct: "resilience (hope and life satisfaction)",
		},
		shade: "#bcaaa4",
		scales: ["CHS", "cantril"],
		xMeasure: "Dissatisfied",
		xMin: 1,
		xMax: 8,
		xThresh: 4.5,
		yMeasure: "Unhopeful",
		yMin: 1,
		yMax: 6,
		yThresh: 3.5,
		reverseX: true,
		reverseY: true,
		quarters: {
			leftbottom: "RESILIENT",
			rightbottom: "MODERATELY RESILIENT",
			lefttop: "MODERATELY RESILIENT",
			righttop: "LESS RESILIENT",
		},
		quarters_sub: {
			leftbottom: "High Hope & High Life Satisfaction",
			rightbottom: "High Hope & Low Life Satisfaction",
			lefttop: "Low Hope & High Life Satisfaction",
			righttop: "Low Hope & Low Life Satisfaction",
		},
		title: "Resilience Score",
		what_does_this_report_show: `<p>To measure student resilience, we use student responses to the Cantril Ladder (Life Satisfaction) and the Children’s Hope Scale (Hope) combined together as a construct.</p>
		<br />
		<p>Resilience is conceptualised as a process of motivation and belief in the ability to change one’s situation along with the development of ways to achieve certain goals, which ultimately result in greater life satisfaction.</p>
		<br />
		<p>Students with high levels of Life Satisfaction and high levels of Hope are categorised as ‘Resilient’, in contrast to students with low-levels of Hope and low-levels of Life Satisfaction who are categorised as ‘Less Resilient’.</p>
		<br />
`,
		purpose: `<p>To provide a ‘Resilience Score’ that can facilitate target-setting and communication.</p>`,
		ask_yourself: `
		<ul>
      		<li>What are the levels of resilience overall?</li>
      		<li>How do levels of resilience differ between genders and year levels?</li>
      		<li>How have levels of resilience changed over time?</li>
			<li>What do you think is the reason for the levels of resilience in your data?</li>
    	</ul>`,
		where_do_these_numbers_come_from: `
		<h6>Raw Score</h6><br />
		<h7> Children’s Hope Scale (CHS):</h7><br />
		<p>The group into which a respondent is classified is determined by their raw score on the shortened 6-item Children's Hope Scale.</p>

		<p>All six items (questions) use the same Likert response scale. For the purposes of computing the overall hope score, responses are encoded as per CHS construct. Higher scores 3.5-6 indicate “High Hope”, whereas scores 1 to <3.5 indicate “Low Hope”.</p><br />

		<h7>Life Satisfaction (Cantril):</h7><br />

		<p>Adapted from the Cantril Self Anchoring Scale, scores range from 1-8, with students imagining each number as a rung on a ladder ranging from worst possible life to best possible life. For the Resilience Score construct, higher scores 5-8 indicate “High Life Satisfaction” whereas scores 1-4 indicate “Low Life Satisfaction”.</p><br />

		<h7>Resilience Score:</h7><br />

		<p>The Resilience Score for each student is represented with a single dot on this scatter plot. The position of each dot indicates each student’s reported levels of Hope and reported levels of Life Satisfaction. In other words, the above scores for both the Childrens Hope Scale and Cantril Ladder allow students to be categorised into one of four quadrants below:</p>

		<ol class="resilience-score">
			<li>RESILIENT = “High Hope & High Life Satisfaction”</li>
			<li>MODERATELY RESILIENT = “Low Hope & High Life Satisfaction”</li>
			<li>MODERATELY RESILIENT = “High Hope & Low Life Satisfaction”</li>
			<li>LESS RESILIENT = “Low Hope & Low Life Satisfaction”</li>
		</ol>

		<p>The percentage of your students who fall into each quadrant is shown on the scatterplot.</p>

		<h6>Relevant Literature</h6>
    	<ol class="literature">
      		<li><a href="https://www.cambridge.org/core/journals/reviews-in-clinical-gerontology/article/abs/what-is-resilience-a-review-and-concept-analysis/B94C9BEAD7F43E1297EC9443DD24CA5C">Windle, G. (2011). What is resilience? A review and concept analysis. Reviews in Clinical Gerontology, 21(2), 152–169. doi:10.1017/S0959259810000420</a></li>
    	</ol>
		`,
	},
];

export default scatterplots;
