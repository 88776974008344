import {neverland as $, render, html, useState} from 'neverland';

// console.log('Importing Loader...');

import Header from './Header.js';
import Footer from './Footer.js';

const Loader = $(function(auth, setAuth, theme) {

	return html`
		<div style="padding-bottom: 20px;">Loading...</div>
	`;

});

export default Loader;