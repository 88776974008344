import {neverland as $, render, html, useState} from 'neverland';

import utilities from '../lib/utilities.js';

let { assert } = utilities;

const LoginPage = $(function(auth, setAuth, theme) {

	function getLogo() {
		if (theme !== null) {
			if (theme.hasOwnProperty('logo') && theme.logo !== '') {
				return '/static/img/logos-large/' + theme.logo;
			}
		}
		return '/static/img/transparent-placeholder.png';
	}

	async function logIn() {

		document.querySelector('#login-error').classList.add('hide');

		// First try to log in using 3.0;

		let username = document.querySelector('#login-username').value,
			password = document.querySelector('#login-password').value;

		// Create credentials.
		const credentials = Realm.Credentials.function({ username, password });

		try {

			// Authenticate the user.
			const user = await auth.app.logIn(credentials);

			assert(user.id === auth.app.currentUser.id);

			// Update client group user ID.
				// TODO - only do this if required.
			const result = await auth.app.currentUser.functions.setUserId(username, password, user.id);

			// Refresh custom data.
			await auth.app.currentUser.refreshCustomData();

			setAuth(prevAuth => {
				let auth = {...prevAuth};
				auth.user = auth.app.currentUser;
				auth.isLoggedIn = true;
				return auth;
			})

			if (window.location.hostname == "localhost") {
				window.location.href = "http://localhost:1234";
			} else {
				window.location.href = "https://" + window.location.hostname;
			}

		} catch(err) {

			document.querySelector('#login-error').classList.remove('hide');
		}

	}

	return html`
		<div class="centre-box login">
			<img src="${getLogo()}" class="squiggle logo-large" />

			<div id="login">
				<span class="loginlabel">USERNAME</span>
				<input type="text" id="login-username" name="username" class="login-field"><br />
				<span class="loginlabel">PASSWORD</span>
				<input type="password" name="password" id="login-password" class="login-field"><br />
				<button id="login-submit" onclick=${logIn}><i class="fal fa-sign-in-alt" class="login-button"></i>&ensp;LOGIN</button>

				<div id="login-error" class="error hide">
					<strong>ACCOUNT NOT FOUND</strong>
					<p>Double-check your username and password, and contact us at <a href="mailto:helpdesk@resilientyouth.org">helpdesk@resilientyouth.org</a> if you continue to have trouble.</p>
				</div>
			</div>
		</div>
	`;

});

export default LoginPage;