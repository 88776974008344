import { neverland as $, render, html, useState, useEffect } from "neverland";

const app = new Realm.App({
	id: "prod-sydney-fglkd",
	timeout: 10000,
});

import utilities from "../lib/utilities.js";

import surveys from "../specs/menus.js";
// TODO - incorporate this ^ into database.

import StyleSheet from "./StyleSheet.js";
import Router from "./Router.js";

let { assert } = utilities;

const App = $(function () {
	function isLoggedIn() {
		if (app.currentUser !== null) {
			if (
				app.currentUser._profile.identities[0].providerType == "custom-function"
			) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	}

	const [auth, setAuth] = useState(() => {
		return {
			app: app,
			user: app.currentUser,
			isLoggedIn: isLoggedIn(),
			db: isLoggedIn()
				? app.currentUser.mongoClient("mongodb-atlas").db("prod")
				: null,

			themeDataLoaded: false,

			userDataLoaded: false,
			userData: {},
			groups: {},
			clientGroupId: null,
			clientGroupName: "",
		};
	});

	// ---------------------------------------------------------------------------- //
	// ---------------------------------------------------------------------------- //
	// LOAD THEME ----------------------------------------------------------------- //
	// ---------------------------------------------------------------------------- //

	let [theme, setTheme] = useState({
		providerName: "",
		dashboardLinksTitle: "",
		dashboardLinks: [],
		dashboardText: "",
		userSupportEmail: "",
		userSupportPhone: "",
		userSupportName: "us",
		copyright: "",
	});

	async function login() {
		if (auth.user === null) {
			console.log("Logging in anonymously...");

			// Create an anonymous credential.
			const credentials = Realm.Credentials.anonymous();

			try {
				// Authenticate the user
				const user = await auth.app.logIn(credentials);

				// `App.currentUser` updates to match the logged in user
				assert(user.id === auth.app.currentUser.id);

				setAuth((prevAuth) => {
					let auth = { ...prevAuth };
					auth.user = user;
					return auth;
				});

				return user;
			} catch (err) {
				console.warn(err);
			}
		}
	}

	async function loadTheme() {
		const themeSpec = await auth.user.functions.fetchTheme(
			window.location.hostname
		);

		if (themeSpec !== undefined) {
			themeSpec.surveys = surveys(themeSpec);
			setTheme({...theme, ...themeSpec});
			setAuth(prevAuth => {
				let auth = {...prevAuth};
				auth.themeDataLoaded = true;
				return auth;
			});
		}
	}

	useEffect(() => {
		login().then(loadTheme);
	}, []);

	useEffect(() => {
		if (theme !== null) {
			if (theme.hasOwnProperty("favicon")) {
				let fileType = theme.favicon.split(".")[1];

				document
					.getElementsByTagName("head")[0]
					.insertAdjacentHTML(
						"beforeend",
						`<link rel="icon shortcut" type="image/${fileType}" href="/static/img/favicons/${theme.favicon}" />`
					);
			}
		}
	}, [JSON.stringify(theme)]);

	// ---------------------------------------------------------------------------- //
	// ---------------------------------------------------------------------------- //
	// LOAD USER DATA ------------------------------------------------------------- //
	// ---------------------------------------------------------------------------- //

	async function fetchUserData(auth) {
		// Refresh Realm custom user data.
		await app.currentUser.refreshCustomData();

		// Fetch remaining user data.
		let userData = auth.user.customData,
			{ db } = auth;

		let primaryPolls = await db
				.collection("Surveys")
				.find({ dataClientGroup: userData._id }),
			polls = await db
				.collection("Surveys")
				.find({ accessClientGroups: userData._id });

		// console.log(userData._id)

		let clientGroups = [];
		for (let p of primaryPolls.concat(polls)) {
			clientGroups.push(p.dataClientGroup);
			clientGroups = clientGroups.concat(p.accessClientGroups);
		}
		clientGroups = await app.currentUser.functions.fetchClientGroups(
			clientGroups
		);

		primaryPolls = primaryPolls.map((p) => {
			p.dataClientGroup = clientGroups.filter(
				(c) => c._id.toString() == p.dataClientGroup
			)[0];
			p.accessClientGroups = p.accessClientGroups.map((CG) => {
				return clientGroups.filter((c) => c._id.toString() == CG)[0];
			});
			return p;
		});
		polls = polls.map((p) => {
			p.dataClientGroup = clientGroups.filter(
				(c) => c._id.toString() == p.dataClientGroup
			)[0];
			p.accessClientGroups = p.accessClientGroups.map((CG) => {
				return clientGroups.filter((c) => c._id.toString() == CG)[0];
			});
			return p;
		});

		userData.primaryPolls = primaryPolls;
		userData.polls = polls;

		// EXTRACT GROUPS FOR PERMISSIONS.

		let permissions = [];
		if (userData.hasOwnProperty("permissions")) {
			permissions = userData.permissions;
		}

		let groups = {};
		for (let p of permissions) {
			groups[p] = true;
		}

		// REDIRECT IF DONT HAVE PERMISSION FOR THIS PAGE. (TODO)

		setAuth((prevAuth) => {
			let auth = { ...prevAuth };
			auth.userData = userData;
			auth.groups = groups;
			auth.clientGroupId = userData._id;
			auth.clientGroupName = userData.name;
			auth.userDataLoaded = true;
			return auth;
		});

		return true;
	}

	useEffect(() => {
		if (auth.isLoggedIn) {
			fetchUserData(auth);
		}
	}, [auth.isLoggedIn]);

	// ---------------------------------------------------------------------------- //

	return html`
		${StyleSheet(theme)}
		${auth.themeDataLoaded ? html`${Router(auth, setAuth, theme)}` : ""}
	`;
});

export default App;
