let comments = [
	{
		group: "student",
		surveys: [
			"student-resilience",
			"short-resilience",
			"relationships-and-no-bullying",
			"learning-curve-wellbeing",
			"phillips-foundation"
		],
		key: "comments",
		hVar: "grade",
		title:
			"If you could magically do anything, what is one thing that you would do to support the wellbeing of young people?",
	},
	{
		group: "student",
		surveys: ["no-bullying"],
		key: "b21",
		hVar: "grade",
		title: "What do you think YOU can do to reduce bullying at your school?",
	},
	{
		group: "student",
		surveys: ["no-bullying"],
		key: "b22",
		hVar: "grade",
		title:
			"What do you think ADULTS could do to reduce bullying at your school?",
	},
	{
		group: "student",
		surveys: ["no-bullying"],
		key: "b23",
		hVar: "grade",
		title: "If you bullied someone, can you tell us why?",
	},
	{
		group: "staff",
		surveys: ["school-staff-resilience"],
		key: "rn50",
		hVar: "agegroup",
		title: "What is the most rewarding part of your job?",
	},
	{
		group: "staff",
		surveys: ["school-staff-resilience"],
		key: "rn51",
		hVar: "agegroup",
		title: "What is the most challenging part of your job?",
	},
	{
		group: "staff",
		surveys: ["school-staff-resilience"],
		key: "rn52",
		hVar: "agegroup",
		title:
			"What is one suggestion you have to improve the wellbeing of staff at this school?",
	},
	{
		group: "staff",
		surveys: ["school-staff-resilience"],
		key: "rn53",
		hVar: "agegroup",
		title:
			"If you have any additional thoughts, suggestions, queries, or concerns, please enter them below.",
	},
	{
		group: "employee",
		surveys: ["workplace-resilience"],
		key: "rn50",
		hVar: "agegroup",
		title: "What is the most rewarding part of your job?",
	},
	{
		group: "employee",
		surveys: ["workplace-resilience"],
		key: "rn51",
		hVar: "agegroup",
		title: "What is the most challenging part of your job?",
	},
	{
		group: "employee",
		surveys: ["workplace-resilience"],
		key: "rn110",
		hVar: "agegroup",
		title: "What is one suggestion you have to improve the wellbeing of staff?",
	},
	{
		group: "employee",
		surveys: ["workplace-resilience"],
		key: "rn53",
		hVar: "agegroup",
		title:
			"If you have any additional thoughts, suggestions, queries, or concerns, please enter them below.",
	},

	{
		group: "parent",
		surveys: ["parent-resilience"],
		key: "rn77",
		hVar: "grade",
		title: "What do you like most about this school?",
	},
	{
		group: "parent",
		surveys: ["parent-resilience"],
		key: "rn78",
		hVar: "grade",
		title:
			"What is one thing you could do to improve the wellbeing of your eldest child at this school?",
	},
	{
		group: "parent",
		surveys: ["parent-resilience"],
		key: "rn79",
		hVar: "grade",
		title:
			"What is one thing the school could do to improve the wellbeing of your eldest child and other students?",
	},
	{
		group: "parent",
		surveys: ["parent-resilience"],
		key: "rn53",
		hVar: "grade",
		title:
			"If you have any additional thoughts, suggestions, queries, or concerns, please enter them below.",
	},
	{
		group: "parent",
		surveys: ["parent-resilience"],
		key: "rn200",
		hVar: "grade",
		title:
			"What three things do you like most about the school?",
	},
	{
		group: "parent",
		surveys: ["parent-resilience"],
		key: "rn201",
		hVar: "grade",
		title:
			"What could the school do to improve the wellbeing of your child and its students?",
	},
	{
		group: "parent",
		surveys: ["parent-resilience"],
		key: "rn202",
		hVar: "grade",
		title:
			"What do you most want for your child?",
	},
	{
		group: "parent",
		surveys: ["parent-resilience"],
		key: "rn203",
		hVar: "grade",
		title:
			"Name three of the favourite things you enjoy doing as a family.",
	},
	{
		group: "parent",
		surveys: ["parent-resilience"],
		key: "rn204",
		hVar: "grade",
		title:
			"If you have any additional thoughts, suggestions, queries, or concerns, please enter them below.",
	},

	{
		group: "student",
		surveys: ["IPE-Mi7"],
		key: "ipe43",
		hVar: "grade",
		title:
			"What are some things in your life right now that make you feel good?",
	},
	{
		group: "student",
		surveys: ["IPE-Mi7"],
		key: "ipe44",
		hVar: "grade",
		title: "What are some things in your life right now that make you unhappy?",
	},
	{
		group: "student",
		surveys: ["IPE-Mi7"],
		key: "ipe45",
		hVar: "grade",
		title:
			"What’s the one thing you would change about your school to make your wellbeing better?",
	},
	{
		group: "student",
		surveys: ["JGI_NatureWellbeingUpper"],
		key: "jg26",
		hVar: "grade",
		title: "What do you like doing the most when outside?",
	},
	{
		group: "student",
		surveys: ["JGI_NatureWellbeingUpper"],
		key: "jg27",
		hVar: "grade",
		title: "What is something about nature that worries you?",
	},
	{
		group: "staff",
		surveys: ["JGI_NatureWellbeingStaff"],
		key: "jg134",
		hVar: "agegroup",
		title:
			"If you used the 'Cool Australia Lesson Plans', could you please list the names of the plans you found useful.",
	},
	{
		group: "staff",
		surveys: ["JGI_NatureWellbeingStaff"],
		key: "jg135",
		hVar: "agegroup",
		title:
			"Could you please list any other resources you used as part of your project that you found useful?",
	},
	{
		group: "staff",
		surveys: ["JGI_NatureWellbeingStaff"],
		key: "jg136",
		hVar: "agegroup",
		title: "Is there anything else you would like to tell us?",
	},
	{
		group: "student",
		surveys: ["kind-schools"],
		key: "k13",
		hVar: "grade",
		title: "Name an act of kindness you have experienced recently.",
	},
	{
		group: "student",
		surveys: ["kind-schools"],
		key: "k14",
		hVar: "grade",
		title: "I show kindness to myself by...",
	},
	{
		group: "student",
		surveys: ["kind-schools"],
		key: "k15",
		hVar: "grade",
		title: "I show kindness to others by...",
	},
	{
		group: "student",
		surveys: ["kind-schools"],
		key: "k16",
		hVar: "grade",
		title: "What do you think kindness means?",
	},
	{
		group: "student",
		surveys: ["kind-schools"],
		key: "k17",
		hVar: "grade",
		title: "When someone is kind to you how do you feel?",
	},
];

export default comments;
