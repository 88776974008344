import katex from "katex";

let math = function (tex, displayMode = false) {
	return katex.renderToString(String(tex), {
		throwOnError: false,
		displayMode: displayMode,
	});
};

let stackedbars = [
	{
		key: "CSI-4",
		group: "student",
		default_hVar: "grade",
		surveys: [
			"student-resilience",
			"short-resilience",
			"relationships-and-no-bullying",
			"phillips-foundation",
		],
		title: "Coping Strategies Inventory (4 item) [adapted]",
		isNew: false,
		firstYear: 2019,
		classes: [
			{
				id: 2,
				label: "Engaged",
				colour: "#66bc62",
			},
			{
				id: 1,
				label: "Disengaged",
				colour: "#f36c42",
			},
		],
		threshold: 2,
		classifier: (x) => (x >= 4 ? 1 : 2),
		showGemfinder: true,
		gemfinderOptions: {
			itemsToExclude: ["cop1", "cop2", "cop3", "cop4"],
			epithets: {
				graph: {
					A: "Disengaged",
					B: "Engaged",
				},
				sentence: {
					A: "who are disengaged",
					B: "who are engaged",
				},
			},
			construct: "engagement",
		},
		what_does_this_report_show: `
			<p>This graph shows the percentage of students who regularly make use of disengaged coping strategies, as measured using a subscale of the Coping Strategies Inventory.</p>
		`,
		purpose: `
			<p>To allow you to track the prevalence of disengaged coping strategies among your students.</p>
		`,
		ask_yourself: `
			<ul>
				<li>Overall, to what extent are students using disengaged coping strategies?</li>
				<li>How does use of such coping strategies differ between genders and year levels?</li>
				<li>How has the use of disengaged copings strategies changed over time?</li>
				<li>What do you think is the reason for the variance in your data?</li>
			</ul>
		`,
		where_do_these_numbers_come_from: `
			<h6>Raw Score</h6>
			<p>The group into which a respondent is classified (either 'Engaged' or 'Disengaged') is determined by their raw score on the shortened and adapted Coping Strategies Inventory, or CSI, which consists of the following questions.
			<ul class="questions">
				<li><a href="../cop1/"><span class="muted">cop1</span> - When I have a problem, I avoid doing anything about it.</a></li>
				<li><a href="../cop2/"><span class="muted">cop2</span> - When I have a problem, I just wish it would go away.</a></li>
				<li><a href="../cop3/"><span class="muted">cop3</span> - When bad things happen, I usually blame myself.</a></li>
				<li><a href="../cop4/"><span class="muted">cop4</span> - When bad things happen, I talk to others about my problem.</a></li>
			</ul>
			<p>All questions use the same Likert response scale. For the purposes of computing the overall disengagement score, responses are encoded as follows:</p>
			<table>
				<tr><th>Response anchor*</th><th>Numerical Encoding</th></tr>
				<tr><td>Never or Rarely</td><td>0</td></tr>
				<tr><td>Sometimes</td><td>0</td></tr>
				<tr><td>Often</td><td>1</td></tr>
				<tr><td>Almost Always</td><td>2</td></tr>
			</table>
			<p><em>* Note that for cop4, the response anchors are reversed.</em></p>
			<p>The adapted CSI score is then calculated by</p>
			<p>${math(
				`\\texttt{disengagement} := \\texttt{cop1} + \\texttt{cop2} + \\texttt{cop3} + \\texttt{cop4}`,
				true
			)}</p>
			<p>giving a score between ${math(0)} and ${math(8)}, inclusive.</p>

			<h6>Threshold</h6>
			<p>Literature on CSI is relatively sparse, and there is not a threshold that is widely agreed upon. We use a threshold of ${math(
				4
			)}, classifying students as disengaged if ${math(
			`\\texttt{disengagement}\\geq 4`
		)}.</p>
			<p>Intuitively, this means that a student would need to be using at least either</p>
			<ul>
				<li>two of the disengaged coping strategies 'Always or Almost Always', or</li>
				<li>two of the disengaged coping strategies 'Often' and one 'Always or Almost Always', or</li>
				<li>all four of the disengaged coping strategies 'Often',</li>
			</ul>
			<p>in order to be classified as disengaged.</p>

			<h6>Relevant Literature</h6>
			<ol class="literature">
				<li><a href="http://www.academia.edu/download/50592066/Manual-Coping-Strategies-Inventory-9.pdf">Tobin, David L., K. A. Holroyd, and R. V. C. Reynolds. "Coping strategies inventory." <em>CSI Manual</em> (1984).</a></li>
				<li><a href="https://link.springer.com/article/10.1007/BF01173478">Tobin, David L., et al. "The hierarchical factor structure of the Coping Strategies Inventory." <em>Cognitive therapy and research</em> 13.4 (1989): 343-361.</a></li>
			</ol>
		`,
	},
	{
		key: "CHS",
		group: "student",
		default_hVar: "grade",
		surveys: [
			"student-resilience",
			"short-resilience",
			"relationships-and-no-bullying",
			"IPE-Mi7",
			"JGI_NatureWellbeingUpper",
			"learning-curve-wellbeing",
			"phillips-foundation",
		],
		title: "Children's Hope Scale",
		isNew: false,
		firstYear: 2015,
		classes: [
			{
				id: 6,
				label: "All",
				colour: "#43a047",
			},
			{
				id: 5,
				label: "Most",
				colour: "#7cb342",
			},
			{
				id: 4,
				label: "Lots",
				colour: "#c0ca33",
			},
			{
				id: 3,
				label: "Some",
				colour: "#fdd835",
			},
			{
				id: 2,
				label: "Little",
				colour: "#ffb300",
			},
			{
				id: 1,
				label: "None",
				colour: "#f4511e",
			},
		],
		threshold: 4,
		classifier: Math.round,
		showGemfinder: true,
		gemfinderOptions: {
			itemsToExclude: ["chs1", "chs2", "chs3", "chs4", "chs5", "chs6"],
			epithets: {
				graph: {
					A: "Hopeful",
					B: "Not hopeful",
				},
				sentence: {
					A: "who are hopeful",
					B: "who are not",
				},
			},
			construct: "hope",
		},
		what_does_this_report_show: `
			<p>This graph shows the percentage of students who exhibit varying degrees of hope according to the Children's Hope Scale (CHS), a widely used pyschometric tool for measuring hope in young people.</p>
		`,
		purpose: `
			<p>To allow you to track levels of hope among your students.</p>
		`,
		ask_yourself: `
			<ul>
				<li>What are the levels of hope overall?</li>
				<li>How do levels of hope differ between genders and year levels?</li>
				<li>How have levels of hope changed over time?</li>
				<li>What do you think is the reason for the variance in your data?</li>
			</ul>
		`,
		where_do_these_numbers_come_from: `
			<h6>Raw Score</h6>
			<p>The group into which a respondent is classified (one of 'All', 'Most', 'Lots', 'Some', 'Little' hope or 'None') is determined by their raw score on the shortened Children's Hope Scale, or CHS, which consists of the following questions.
			<ul class="questions">
				<li><a href="../chs1/"><span class="muted">chs1</span> - In most situations I think I am doing pretty well.</a></li>
				<li><a href="../chs2/"><span class="muted">chs2</span> - In most situations I can think of many ways to get things in life that are most important to me.</a></li>
				<li><a href="../chs3/"><span class="muted">chs3</span> - In most situations I am doing just as well as other kids my age.</a></li>
				<li><a href="../chs4/"><span class="muted">chs4</span> - In most situations when I have a problem, I can come up with lots of ways to solve it.</a></li>
				<li><a href="../chs5/"><span class="muted">chs5</span> - In most situations I think the things I have done in the past will help me in the future.</a></li>
				<li><a href="../chs6/"><span class="muted">chs6</span> - In most situations even when others want to quit, I know that I can find ways to solve the problem.</a></li>
			</ul>
			<p>All six questions use the same Likert response scale. For the purposes of computing the overall hope score, responses are encoded as follows:</p>
			<table>
				<tr><th>Response anchor</th><th>Numerical Encoding</th></tr>
				<tr><td>None of the time</td><td>1</td></tr>
				<tr><td>A little of the time</td><td>2</td></tr>
				<tr><td>Some of the time</td><td>3</td></tr>
				<tr><td>A lot of the time</td><td>4</td></tr>
				<tr><td>Most of the time</td><td>5</td></tr>
				<tr><td>All of the time</td><td>6</td></tr>
			</table>
			<p>The overall CHS hope score is then calculated by</p>
			<p>${math(
				`\\texttt{hope} := (\\texttt{chs1} + \\texttt{chs2} + \\texttt{chs3} + \\texttt{chs4} + \\texttt{chs5} + \\texttt{chs6})/6`,
				true
			)}</p>
			<p>giving a score between ${math(1)} and ${math(6)}, inclusive.</p>

			<h6>Thresholds</h6>
			<p>Some literature on the CHS suggests classifying respondents' overall levels of hope as 'All' if ${math(
				`\\texttt{hope}\\geq5.5`
			)}, 'Most' if ${math(`4.5\\leq\\texttt{hope}<5.5`)}, 'Lots' if ${math(
			`3.5\\leq\\texttt{hope}<4.5`
		)}, 'Some' if ${math(`2.5\\leq\\texttt{hope}<3.5`)}, 'Little' if ${math(
			`1.5\\leq\\texttt{hope}<2.5`
		)} and 'None' if ${math(
			`\\texttt{hope}<1.5`
		)}. We use these groups in the above graph.

			<h6>Relevant Literature</h6>
			<ol class="literature">
				<li><a href="https://academic.oup.com/jpepsy/article/22/3/399/917485">Snyder, C. Richard, et al. "The development and validation of the Children’s Hope Scale." <em>Journal of pediatric psychology</em> 22.3 (1997): 399-421.</a></li>
				<li><a href="https://journals.sagepub.com/doi/abs/10.1177/073428290402200403">Valle, Michael F., E. Scott Huebner, and Shannon M. Suldo. "Further evaluation of the Children's Hope Scale." <em>Journal of Psychoeducational Assessment</em> 22.4 (2004): 320-337.</a></li>
			</ol>
		`,
	},
	{
		key: "PHQ-2",
		group: "student",
		default_hVar: "grade",
		surveys: [
			"student-resilience",
			"short-resilience",
			"relationships-and-no-bullying",
			"IPE-Mi7",
			"JGI_NatureWellbeingUpper",
			"learning-curve-wellbeing",
			"phillips-foundation",
		],
		title: "Patient Health Questionnaire (2 item)",
		isNew: false,
		firstYear: 2019,
		classes: [
			{
				id: 2,
				label: "Not Depressed",
				colour: "#66bc62",
			},
			{
				id: 1,
				label: "Depressed",
				colour: "#f36c42",
			},
		],
		threshold: 2,
		classifier: (x) => (x >= 3 ? 1 : 2),
		showGemfinder: true,
		gemfinderOptions: {
			epithets: {
				itemsToExclude: ["ph3", "ph4"],
				graph: {
					A: "Depressed",
					B: "Not depressed",
				},
				sentence: {
					A: "who are depressed",
					B: "who are not",
				},
			},
			construct: "depression",
		},
		what_does_this_report_show: `
			<p>This graph shows the percentage of students who exhibit symptoms of depression according to the PHQ-2 - a validated, two-question screening tool.</p>
		`,
		purpose: `
			<p>To allow you to track the prevalence of depressive symptoms among your students.</p>
		`,
		ask_yourself: `
			<ul>
				<li>What percentage of our students self-report symptoms of depression?</li>
				<li>How does the prevalence of depressive symptoms differ between genders and year levels?</li>
				<li>How has the percentage of students who experience depression changed over time?</li>
				<li>What do you think is the reason for the variance in your data?</li>
			</ul>
		`,
		where_do_these_numbers_come_from: `
			<h6>Raw Score</h6>
			<p>The group into which a respondent is classified (either 'Depressed' or 'Not Depressed') is determined by their raw score on the shortened Patient Health Questionnaire scale, or PHQ-2, which consists of the following questions.
			<ul class="questions">
				<li>Over the last 2 weeks, how often have you been bothered by the following problems?
					<ul>
						<li><a href="../ph3/"><span class="muted">ph3</span> - Feeling down, depressed or hopeless.</a></li>
						<li><a href="../ph4/"><span class="muted">ph4</span> - Little interest or pleasure in doing things.</a></li>
					</ul>
				</li>
			</ul>
			<p>Both questions use the same Likert response scale. For the purposes of computing the overall depression score, responses are encoded as follows:</p>
			<table>
				<tr><th>Response anchor</th><th>Numerical Encoding</th></tr>
				<tr><td>Not at all</td><td>0</td></tr>
				<tr><td>Several days</td><td>1</td></tr>
				<tr><td>More than half the days</td><td>2</td></tr>
				<tr><td>Nearly every day</td><td>3</td></tr>
			</table>
			<p>The PHQ-2 score is then calculated by</p>
			<p>${math(`\\texttt{PHQ-2} := \\texttt{ph3} + \\texttt{ph4}`, true)}</p>
			<p>giving a score between ${math(0)} and ${math(6)}, inclusive.</p>

			<h6>Threshold</h6>
			<p>Literature on the PHQ-2 (see the articles linked below) suggests that a threshold of ${math(
				3
			)} provides acceptable sensitivity, specificity and discriminant validity to be used as a measure of depressive disorders. As such, we classify students as 'Depressed' if ${math(
			`\\texttt{PHQ-2}\\geq 3`
		)}, and 'Not Depressed' otherwise ${math(`\\texttt{PHQ-2}< 3`)}.</p>

			<h6>Relevant Literature</h6>
			<ol class="literature">
				<li><a href="https://www-cambridge-org.ezp.lib.unimelb.edu.au/core/services/aop-cambridge-core/content/view/A2083386E3287C1F982BCC82DCEA1CB6/S2056472400001265a.pdf/case_finding_and_screening_clinical_utility_of_the_patient_health_questionnaire_phq9_and_phq2_for_depression_in_primary_care_a_diagnostic_metaanalysis_of_40_studies.pdf">Mitchell, Alex J., et al. "Case finding and screening clinical utility of the Patient Health Questionnaire (PHQ-9 and PHQ-2) for depression in primary care: a diagnostic meta-analysis of 40 studies." <em>BJPsych open</em> 2.2 (2016): 127-138.</a></li>
				<li><a href="https://www.sciencedirect.com/science/article/abs/pii/S0022399904006361">Löwe, Bernd, Kurt Kroenke, and Kerstin Gräfe. "Detecting and monitoring depression with a two-item questionnaire (PHQ-2)." <em>Journal of psychosomatic research</em> 58.2 (2005): 163-171.</a></li>
				<li><a href="https://www.jstor.org/stable/3768417">Kroenke, Kurt, Robert L. Spitzer, and Janet BW Williams. "The Patient Health Questionnaire-2: validity of a two-item depression screener." <em>Medical care</em> (2003): 1284-1292.</a></li>
				<li><a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3100798/">Richardson, Laura P., et al. "Evaluation of the PHQ-2 as a brief screen for detecting major depression among adolescents." <em>Pediatrics</em> 125.5 (2010): e1097-e1103.</a></li>
				<li><a href="http://www.annfammed.org/content/8/4/348.long">Arroll, Bruce, et al. "Validation of PHQ-2 and PHQ-9 to screen for major depression in the primary care population." <em>The Annals of Family Medicine</em> 8.4 (2010): 348-353.</a></li>
				<li><a href="https://www.sciencedirect.com/science/article/pii/S0163834318303712?via%3Dihub">Staples, Lauren G., et al. "Psychometric properties and clinical utility of brief measures of depression, anxiety, and general distress: The PHQ-2, GAD-2, and K-6." <em>General hospital psychiatry</em> 56 (2019): 13-18.</a></li>
			</ol>
		`,
	},
	{
		key: "GAD-2",
		group: "student",
		default_hVar: "grade",
		surveys: [
			"student-resilience",
			"short-resilience",
			"relationships-and-no-bullying",
			"IPE-Mi7",
			"JGI_NatureWellbeingUpper",
			"learning-curve-wellbeing",
			"kind-schools",
			"phillips-foundation",
		],
		title: "Generalized Anxiety Disorder scale (2 item)",
		isNew: false,
		firstYear: 2019,
		classes: [
			{
				id: 2,
				label: "Not Anxious",
				colour: "#66bc62",
			},
			{
				id: 1,
				label: "Anxious",
				colour: "#f36c42",
			},
		],
		threshold: 2,
		classifier: (x) => (x >= 3 ? 1 : 2),
		showGemfinder: true,
		gemfinderOptions: {
			itemsToExclude: ["ph1", "ph2"],
			epithets: {
				graph: {
					A: "Anxious",
					B: "Not anxious",
				},
				sentence: {
					A: "who are anxious",
					B: "who are not",
				},
			},
			construct: "anxiety",
		},
		what_does_this_report_show: `
			<p>This graph shows the percentage of students who exhibit symptoms of anxiety according to the GAD-2 - a validated, two-question screening tool.</p>
		`,
		purpose: `
			<p>To allow you to track the prevalence of anxiety among your students.</p>
		`,
		ask_yourself: `
			<ul>
				<li>What percentage of our students self-report symptoms of anxiety?</li>
				<li>How do levels of anxiety differ between genders and year levels?</li>
				<li>How has the percentage of students who are anxious changed over time?</li>
				<li>What do you think is the reason for the variance in your data?</li>
			</ul>
		`,
		where_do_these_numbers_come_from: `
			<h6>Raw Score</h6>
			<p>The group into which a respondent is classified (either 'Anxious' or 'Not Anxious') is determined by their raw score on the shortened General Anxiety Disorder scale, or GAD-2, which consists of the following questions.
			<ul class="questions">
				<li>Over the last 2 weeks, how often have you been bothered by the following problems?
					<ul>
						<li><a href="../ph1/"><span class="muted">ph1</span> - Feeling nervous, anxious, or on edge.</a></li>
						<li><a href="../ph2"><span class="muted">ph2</span> - Not being able to stop or control worrying.</a></li>
					</ul>
				</li>
			</ul>
			<p>Both questions use the same Likert response scale. For the purposes of computing the overall anxiety score, responses are encoded as follows:</p>
			<table>
				<tr><th>Response anchor</th><th>Numerical Encoding</th></tr>
				<tr><td>Not at all</td><td>0</td></tr>
				<tr><td>Several days</td><td>1</td></tr>
				<tr><td>More than half the days</td><td>2</td></tr>
				<tr><td>Nearly every day</td><td>3</td></tr>
			</table>
			<p>The GAD-2 score is then calculated by</p>
			<p>${math(`\\texttt{GAD-2} := \\texttt{ph1} + \\texttt{ph2}`, true)}</p>
			<p>giving a score between ${math(0)} and ${math(6)}, inclusive.</p>

			<h6>Threshold</h6>
			<p>Literature on the GAD-2 (see the articles linked below) suggests that a threshold of ${math(
				3
			)} provides acceptable sensitivity, specificity and discriminant validity to be used as a measure of anxiety disorders and general stress. As such, we classify students as 'Anxious' if ${math(
			`\\texttt{GAD-2}\\geq 3`
		)}, and 'Not Anxious' otherwise ${math(`\\texttt{GAD-2}< 3`)}.</p>

			<h6>Relevant Literature</h6>
			<ol class="literature">
				<li><a href="https://s3.amazonaws.com/academia.edu.documents/41377550/1-s2.0-S0163834315002406-main.pdf?response-content-disposition=inline%3B%20filename%3DScreening_for_anxiety_disorders_with_the.pdf&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIATUSBJ6BALQOGIJB4%2F20200428%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20200428T231700Z&X-Amz-Expires=3600&X-Amz-Security-Token=IQoJb3JpZ2luX2VjELf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIE1FK6RxfDigp%2BdfMasvjgaGuw2KEN2bJKKwVOWXzBADAiEA%2F0pyV1hXhf1MSDdYgvZm2NoDc6GiFuRf7lTJPiRsrsUqvQMI4P%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAAGgwyNTAzMTg4MTEyMDAiDBBFukGo%2BIYb9cKRFCqRA3lRioiCa1q7OYYucTWmC7exOFwGrmxd680wDCGMzLL0hL02SCVcpRVJbgWTGkqK0RM0Fnc3YLvp8Ql8kpeJncVpmIs8vLv4NkeoG38Mlvn%2Bj%2BCrdhjLN9QiF8voDoI3hcKHHwnQY%2F2UMS%2FZpSfsWgVUO%2B51ThUvVgP6fO3EcVMg6T7eizdielfcpeX5fWDaDPBClJQuM5ZDmN1SYIRAeX2FMpTauKyT9tx%2F30pqQLj2mM1qbWdjoDTCzKX7GSoC4URQeYEBgD7jxauDklexacAGSIs2tsEK9Ein9Qv%2F7O%2FVFqj79IJtP575UrsUe19wD6LeWKJ6f7VV7MtQiUTD%2FL9a7WUvobgWkDUHI2oNcHgM4oPfH08EgGeKlEStVSDddY4u3g2k6H9R2DR3muX8dQiALVcIWaN2b0wL5A%2B4zp5NnOfKNbXPuV9dPOnGelZElCoVfkPgaBWtckUOhA5KIEO6mXk1BHnStrIHN1umQnZhNQ9TsWi9cGI9%2BsvKPxw6SVLaoqZwilqQNStMnLjL56ePMNXqovUFOusBOu9PYb8I56ugePDCxA%2FVDtTEwt49JwdECHFVjQYpkimD9enu2e%2BSfUIznZlLpF%2FdYHJ65FNrGF32kN0Tif4zWgdnEAbjC26tzitvGweYczOy%2FvE0gRw7znBLKKf5vsEIDDr7euj9HhoWq9eE0gc9PosRboycFoojiEpAL0pRE3N8ZNTqEOJrOpNNeAQ1ZQQKvPp%2BX99te781K8HD%2FJ79KoeyhywFG3HpB%2BpefNg%2BmQ3d1VlazsDgeNk0IVO8lWlkjadV8B4NGSJbgCY6coZh1YQzRb6pqqomm%2Fumt9pUPJfpOCn18Ne2YfT1tA%3D%3D&X-Amz-SignedHeaders=host&X-Amz-Signature=44f443f777c4972aacf83379d1189f868468bfa3320e9fdcffed49b23a21842a">Plummer, Faye, et al. "Screening for anxiety disorders with the GAD-7 and GAD-2: a systematic review and diagnostic metaanalysis." <em>General hospital psychiatry</em> 39 (2016): 24-31.</a></li>
				<li><a href="https://www.sciencedirect.com/science/article/pii/S0163834318303712?via%3Dihub">Staples, Lauren G., et al. "Psychometric properties and clinical utility of brief measures of depression, anxiety, and general distress: The PHQ-2, GAD-2, and K-6." <em>General hospital psychiatry</em> 56 (2019): 13-18.</a></li>
			</ol>
		`,
	},
	{
		key: "cantril",
		group: "student",
		default_hVar: "grade",
		surveys: [
			"student-resilience",
			"short-resilience",
			"relationships-and-no-bullying",
			"IPE-Mi7",
			"JGI_NatureWellbeingUpper",
			"learning-curve-wellbeing",
			"phillips-foundation",
		],
		title: "Cantril Ladder [adapted]",
		isNew: false,
		firstYear: 2019,
		classes: [
			{
				id: 8,
				label: "8 (best)",
				colour: "#388e3c",
			},
			{
				id: 7,
				label: "7",
				colour: "#43a047",
			},
			{
				id: 6,
				label: "6",
				colour: "#7cb342",
			},
			{
				id: 5,
				label: "5",
				colour: "#c0ca33",
			},
			{
				id: 4,
				label: "4",
				colour: "#fdd835",
			},
			{
				id: 3,
				label: "3",
				colour: "#ffb300",
			},
			{
				id: 2,
				label: "2",
				colour: "#fb8c00",
			},
			{
				id: 1,
				label: "1 (worst)",
				colour: "#f4511e",
			},
		],
		threshold: 5,
		classifier: Number,
		showGemfinder: true,
		gemfinderOptions: {
			epithets: {
				graph: {
					A: "Rungs 5-8",
					B: "Rungs 1-4",
				},
				sentence: {
					A: "on rungs 5-8",
					B: "on rungs 1-4",
				},
			},
			construct: "life satisfaction",
		},
		what_does_this_report_show: `
			<p>The question posed to students was 'Imagine a ladder with steps numbered 1 at the bottom to 8 at the top. The top represents the best possible life for you and the bottom represents the worst possible life for you. On which step of the ladder do you feel you stand at this time?'. This question is an adapted version of the Cantril Ladder, a widely used survey tool for measing life satisfaction. The graph above shows the percentage of students who identified themselves as being on each rung of the ladder.</p>
		`,
		purpose: `
			<p>To allow you to track levels of overall life satisfaction among your students.</p>
		`,
		ask_yourself: `
			<ul>
				<li>What is the overall distribution of life satisfaction?</li>
				<li>How does life satisfaction differ between genders and year levels?</li>
				<li>How has life satisfaction changed over time?</li>
				<li>What do you think is the reason for the variance in your data?</li>
			</ul>
		`,
		where_do_these_numbers_come_from: `
			<p>Respondents are simply grouped by their answer to the following question, which is an adapted version of the Cantril ladder.
			<ul class="questions">
				<li><span class="muted">cantril</span> - Imagine a ladder with steps numbered 1 at the bottom to 8 at the top. The top represents the best possible life for you and the bottom represents the worst possible life for you. On which step of the ladder do you feel you stand at this time?</li>
			</ul>
			<p>The graph above simply plots the percentage of respondents who self-identified as being on each of the eight rungs of the ladder.</p>

			<p>Note that this version of the Cantril ladder differs slightly from versions used elsewhere in that the wording has been simplified and we use an 8-point (1-8), rather than a 11-point (0-10) scale.</p>

			<h6>Relevant Literature</h6>
			<ol class="literature">
				<li><a href="https://link.springer.com/article/10.1007/s11205-013-0507-4">Levin, Kate A., and Candace Currie. "Reliability and validity of an adapted version of the Cantril Ladder for use with adolescent samples." Social Indicators Research 119.2 (2014): 1047-1063.</a></li>
				<li><a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5778415/">Mazur, Joanna, et al. "What does the Cantril Ladder measure in adolescence?." <em>Archives of medical science: AMS</em> 14.1 (2018): 182.</a></li>
			</ol>
		`,
	},
	{
		key: "IINS",
		group: "student",
		default_hVar: "grade",
		surveys: [
			"student-resilience",
			"short-resilience",
			"relationships-and-no-bullying",
			"IPE-Mi7",
			"JGI_NatureWellbeingUpper",
			"learning-curve-wellbeing",
			"phillips-foundation"
		],
		title: "Illustrated Inclusion of Nature in Self Scale",
		isNew: false,
		firstYear: 2023,
		classes: [
			{
				id: 3,
				label: "STRONGER conection to nature",
				colour: "#43a047",
			},
			{
				id: 2,
				label: "MODERATE connection to nature",
				colour: "#fdd835",
			},
			{
				id: 1,
				label: "WEAKER connection to nature",
				colour: "#f4511e",
			},
		],
		threshold: 3,
		classifier: (x) => (x < 3 ? 1 : x == 3 ? 2 : 3),
		showGemfinder: true,
		gemfinderOptions: {
			itemsToExclude: ["IINS"],
			epithets: {
				graph: {
					A: "Stronger connection",
					B: "Moderate / weaker connection",
				},
				sentence: {
					A: "who have a stronger connection to nature",
					B: "who do not",
				},
			},
			construct: "connection to nature",
		},
		what_does_this_report_show: `
			<p>The question to students was 'How connected are you with nature?' (Picture response):</p>
		`,
		purpose: `
			<p>To allow you to track levels of connection to nature among your students.</p>
		`,
		ask_yourself: `
			<ul>
				<li>What is the overall distribution of connection to nature?</li>
				<li>How does connection to nature differ between genders and year levels?</li>
				<li>How has connection to nature changed over time?</li>
				<li>What do you think is the reason for the variance in your data?</li>
			</ul>
		`,
		where_do_these_numbers_come_from: `
			<p>Respondents are simply grouped by their answer to the following question, which is an adapted version of the Illustrated Inclusion of Nature in Self Scale (IINS).</p>
			
			<strong>WEAKER CONNECTION TO NATURE (responses A, B):</strong>
			<p>Young people in this category report a weaker emotional and experiential connection to nature. Students in this category are less likely to have positive thoughts and attitudes about the importance of the environment, enjoyment in it, and responsibility for it.</p>

			<strong>MODERATE CONNECTION TO NATURE (responses C):</strong>
			<p>Young people in this category report moderate emotional and experiential connection to nature. This connection is predictive of occasional positive thoughts and attitudes about the importance of the environment, enjoyment in it, and responsibility for it.</p>

			<strong>STRONGER CONNECTION TO NATURE (responses D, E):</strong>
			<p>Young people in this category report a strong emotional and experiential connection to nature. This connection is predictive of positive thoughts and attitudes about the importance of the environment, enjoyment in it, and responsibility for it.</p>
			
			<h6>Relevant Literature</h6>
			<ol class="literature">
				<li><a href="https://www.mdpi.com/2071-1050/13/4/1761">Kleespies, Matthias Winfried, et al. &quot;Measuring connection to nature—a illustrated extension of the inclusion of nature in self scale.&quot; Sustainability 13.4 (2021): 1761.</a></li>
				<li><a href="https://journals.sagepub.com/doi/abs/10.1177/0013916518806686">Larson, L. R., Szczytko, R., Bowers, E. P., Stephens, L. E., Stevenson, K. T., &amp; Floyd, M. F. (2018). Outdoor time, screen time, and connection to nature: Troubling trends among rural youth? Environment and Behavior, 51(8), 966-991.</a></li>
			</ol>
		`,
	},
	{
		key: "cantril1",
		group: "staff",
		default_hVar: "agegroup",
		surveys: ["school-staff-resilience"],
		title: "Life Satisfaction",
		isNew: false,
		firstYear: 2019,
		classes: [
			{
				id: 8,
				label: "8 (best)",
				colour: "#388e3c",
			},
			{
				id: 7,
				label: "7",
				colour: "#43a047",
			},
			{
				id: 6,
				label: "6",
				colour: "#7cb342",
			},
			{
				id: 5,
				label: "5",
				colour: "#c0ca33",
			},
			{
				id: 4,
				label: "4",
				colour: "#fdd835",
			},
			{
				id: 3,
				label: "3",
				colour: "#ffb300",
			},
			{
				id: 2,
				label: "2",
				colour: "#fb8c00",
			},
			{
				id: 1,
				label: "1 (worst)",
				colour: "#f4511e",
			},
		],
		threshold: 5,
		classifier: Number,
		what_does_this_report_show: `
			<p>The question posed to staff was 'Imagine a ladder with steps numbered 1 at the bottom to 8 at the top. The top represents the best possible life for YOU and the bottom represents the worst possible life for YOU. On which step of the ladder do you feel YOU stand at this time?'. This question is an adapted version of the Cantril Ladder, a widely used survey tool for measing life satisfaction. The graph above shows the percentage of staff who identified themselves as being on each rung of the ladder.</p>
		`,
		purpose: `
			<p>To allow you to track levels of overall life satisfaction among your staff.</p>
		`,
		ask_yourself: `
			<ul>
				<li>What is the overall distribution of life satisfaction?</li>
				<li>How does life satisfaction differ between genders and age groups?</li>
				<li>How has life satisfaction changed over time?</li>
				<li>What do you think is the reason for the variance in your data?</li>
			</ul>
		`,
		where_do_these_numbers_come_from: `
			<p>Respondents are simply grouped by their answer to the following question, which is an adapted version of the Cantril ladder.
			<ul class="questions">
				<li><span class="muted">cantril</span> - Imagine a ladder with steps numbered 1 at the bottom to 8 at the top. The top represents the best possible life for YOU and the bottom represents the worst possible life for YOU. On which step of the ladder do you feel YOU stand at this time?</li>
			</ul>
			<p>The graph above simply plots the percentage of respondents who self-identified as being on each of the eight rungs of the ladder.</p>

			<p>Note that this version of the Cantril ladder differs slightly from versions used elsewhere in that the wording has been simplified and we use an 8-point (1-8), rather than a 11-point (0-10) scale.</p>
		`,
	},

	{
		key: "cantril2",
		group: "staff",
		default_hVar: "agegroup",
		surveys: ["school-staff-resilience"],
		title: "Job Satisfaction",
		isNew: false,
		firstYear: 2019,
		classes: [
			{
				id: 8,
				label: "8 (best)",
				colour: "#388e3c",
			},
			{
				id: 7,
				label: "7",
				colour: "#43a047",
			},
			{
				id: 6,
				label: "6",
				colour: "#7cb342",
			},
			{
				id: 5,
				label: "5",
				colour: "#c0ca33",
			},
			{
				id: 4,
				label: "4",
				colour: "#fdd835",
			},
			{
				id: 3,
				label: "3",
				colour: "#ffb300",
			},
			{
				id: 2,
				label: "2",
				colour: "#fb8c00",
			},
			{
				id: 1,
				label: "1 (worst)",
				colour: "#f4511e",
			},
		],
		threshold: 5,
		classifier: Number,
		what_does_this_report_show: `
			<p>The question posed to staff was 'Imagine a ladder with steps numbered 1 at the bottom to 8 at the top. The top represents very satisfied with YOUR JOB and the bottom represents very dissatisfied with YOUR JOB. On which step of the ladder do you feel you stand at this time?'. This question is an adapted version of the Cantril Ladder, a widely used survey tool for measing life satisfaction. The graph above shows the percentage of staff who identified themselves as being on each rung of the ladder.</p>
		`,
		purpose: `
			<p>To allow you to track levels of overall job satisfaction among your staff.</p>
		`,
		ask_yourself: `
			<ul>
				<li>What is the overall distribution of job satisfaction?</li>
				<li>How does job satisfaction differ between genders and age groups?</li>
				<li>How has job satisfaction changed over time?</li>
				<li>What do you think is the reason for the variance in your data?</li>
			</ul>
		`,
		where_do_these_numbers_come_from: `
			<p>Respondents are simply grouped by their answer to the following question, which is an adapted version of the Cantril ladder.
			<ul class="questions">
				<li><span class="muted">cantril2</span> - Imagine a ladder with steps numbered 1 at the bottom to 8 at the top. The top represents very satisfied with YOUR JOB and the bottom represents very dissatisfied with YOUR JOB. On which step of the ladder do you feel you stand at this time?</li>
			</ul>
			<p>The graph above simply plots the percentage of respondents who self-identified as being on each of the eight rungs of the ladder.</p>

			<p>Note that this version of the Cantril ladder differs slightly from versions used elsewhere in that the wording has been simplified, it asks about job (rather than life) satisfaction, and we use an 8-point (1-8), rather than a 11-point (0-10) scale.</p>
		`,
	},

	{
		key: "cantril3",
		group: "staff",
		default_hVar: "agegroup",
		surveys: ["school-staff-resilience"],
		title: "Workplace Satisfaction",
		isNew: false,
		firstYear: 2019,
		classes: [
			{
				id: 8,
				label: "8 (best)",
				colour: "#388e3c",
			},
			{
				id: 7,
				label: "7",
				colour: "#43a047",
			},
			{
				id: 6,
				label: "6",
				colour: "#7cb342",
			},
			{
				id: 5,
				label: "5",
				colour: "#c0ca33",
			},
			{
				id: 4,
				label: "4",
				colour: "#fdd835",
			},
			{
				id: 3,
				label: "3",
				colour: "#ffb300",
			},
			{
				id: 2,
				label: "2",
				colour: "#fb8c00",
			},
			{
				id: 1,
				label: "1 (worst)",
				colour: "#f4511e",
			},
		],
		threshold: 5,
		classifier: Number,
		what_does_this_report_show: `
			<p>The question posed to staff was 'Imagine a ladder with steps numbered 1 at the bottom to 8 at the top. The top represents very satisfied with YOUR WORKPLACE and the bottom represents very dissatisfied with YOUR WORKPLACE. On which step of the ladder do you feel you stand at this time?'. This question is an adapted version of the Cantril Ladder, a widely used survey tool for measing life satisfaction. The graph above shows the percentage of staff who identified themselves as being on each rung of the ladder.</p>
		`,
		purpose: `
			<p>To allow you to track levels of overall workplace satisfaction among your staff.</p>
		`,
		ask_yourself: `
			<ul>
				<li>What is the overall distribution of workplace satisfaction?</li>
				<li>How does workplace satisfaction differ between genders and age groups?</li>
				<li>How has workplace satisfaction changed over time?</li>
				<li>What do you think is the reason for the variance in your data?</li>
			</ul>
		`,
		where_do_these_numbers_come_from: `
			<p>Respondents are simply grouped by their answer to the following question, which is an adapted version of the Cantril ladder.
			<ul class="questions">
				<li><span class="muted">cantril3</span> - Imagine a ladder with steps numbered 1 at the bottom to 8 at the top. The top represents very satisfied with YOUR WORKPLACE and the bottom represents very dissatisfied with YOUR WORKPLACE. On which step of the ladder do you feel you stand at this time?</li>
			</ul>
			<p>The graph above simply plots the percentage of respondents who self-identified as being on each of the eight rungs of the ladder.</p>

			<p>Note that this version of the Cantril ladder differs slightly from versions used elsewhere in that the wording has been simplified, it asks about workplace (rather than life) satisfaction, and we use an 8-point (1-8), rather than a 11-point (0-10) scale.</p>
		`,
	},

	{
		key: "AHS",
		group: "staff",
		default_hVar: "agegroup",
		surveys: ["school-staff-resilience"],
		title: "Adult Hope Scale",
		isNew: false,
		firstYear: 2020,
		classes: [
			{
				id: 6,
				label: "All",
				colour: "#43a047",
			},
			{
				id: 5,
				label: "Most",
				colour: "#7cb342",
			},
			{
				id: 4,
				label: "Lots",
				colour: "#c0ca33",
			},
			{
				id: 3,
				label: "Some",
				colour: "#fdd835",
			},
			{
				id: 2,
				label: "Little",
				colour: "#ffb300",
			},
			{
				id: 1,
				label: "None",
				colour: "#f4511e",
			},
		],
		threshold: 4,
		classifier: function (x) {
			if (x >= 15) {
				return 6;
			} else if (x >= 13) {
				return 5;
			} else if (x >= 10) {
				return 4;
			} else if (x >= 8) {
				return 3;
			} else if (x >= 6) {
				return 2;
			} else {
				return 1;
			}
		},
		what_does_this_report_show: `
			<p>This graph shows the percentage of staff who exhibit varying degrees of hope according to a simplified version of the Adult Hope Scale (AHS), a widely used pyschometric tool for measuring hope.</p>
		`,
		purpose: `
			<p>To allow you to track levels of hope among your staff.</p>
		`,
		ask_yourself: `
			<ul>
				<li>What are the levels of hope overall?</li>
				<li>How do levels of hope differ between genders and age groups?</li>
				<li>How have levels of hope changed over time?</li>
				<li>What do you think is the reason for the variance in your data?</li>
			</ul>
		`,
		where_do_these_numbers_come_from: `
			<h6>Raw Score</h6>
			<p>The group into which a respondent is classified (one of 'All', 'Most', 'Lots', 'Some', 'Little' hope or 'None') is determined by their raw score on an adpated version of the Adult Hope Scale, or AHS, which consists of the following questions.
			<ul class="questions">
				<li><a href="../ahs1/?sm=m-hope-staff,m-AHS-items-staff&mp=school-staff-resilience-interactive"><span class="muted">ahs1</span> - I can think of many ways to get out of a bad situation.</a></li>
				<li><a href="../ahs3/?sm=m-hope-staff,m-AHS-items-staff&mp=school-staff-resilience-interactive"><span class="muted">ahs3</span> - I believe there are lots of ways around any problem.</a></li>
				<li><a href="../ahs4/?sm=m-hope-staff,m-AHS-items-staff&mp=school-staff-resilience-interactive"><span class="muted">ahs4</span> - I can think of many ways to get the things in life that are most important to me.</a></li>
				<li><a href="../ahs5/?sm=m-hope-staff,m-AHS-items-staff&mp=school-staff-resilience-interactive"><span class="muted">ahs5</span> - Even when others get discouraged, I know I can find a way to solve the problem.</a></li>
			</ul>
			<p>All four questions use the same Likert response scale. For the purposes of computing the overall hope score, responses are encoded as follows:</p>
			<table>
				<tr><th>Response anchor</th><th>Numerical Encoding</th></tr>
				<tr><td>Never or Rarely</td><td>1</td></tr>
				<tr><td>Sometimes</td><td>2</td></tr>
				<tr><td>Often</td><td>3</td></tr>
				<tr><td>Always or Almost Always</td><td>4</td></tr>
			</table>
			<p>The overall AHS hope score is then calculated by</p>
			<p>${math(
				`\\texttt{hope} := \\texttt{ahs1} + \\texttt{ahs3} + \\texttt{ahs4} + \\texttt{ahs5}`,
				true
			)}</p>
			<p>giving a score between ${math(4)} and ${math(16)}, inclusive.</p>

			<h6>Thresholds</h6>
			<p>In the literature, the AHS is usually reported as a continuous score, rather than being used to classify respondents into groups according to their reported levels of hope. For ease of communication, we report equivalent groups to those sometimes used in the Children\'s Hope Scale (CHS). We classify respondents' overall levels of hope as 'All' if ${math(
				`\\texttt{hope}\\geq15`
			)}, 'Most' if ${math(`13\\leq\\texttt{hope}<15`)}, 'Lots' if ${math(
			`10\\leq\\texttt{hope}<13`
		)}, 'Some' if ${math(`8\\leq\\texttt{hope}<10`)}, 'Little' if ${math(
			`6\\leq\\texttt{hope}<8`
		)} and 'None' if ${math(`\\texttt{hope}<6`)}.

			<h6>Relevant Literature</h6>
			<ol class="literature">
				<li><a href="https://psycnet.apa.org/buy/1991-17270-001">Snyder, Charles R., et al. "The will and the ways: development and validation of an individual-differences measure of hope." <em>Journal of personality and social psychology</em> 60.4 (1991): 570.</a></li>
				<li><a href="https://www.tandfonline.com/doi/abs/10.1207/S15327965PLI1304_01">Snyder, C. Richard. "Hope theory: Rainbows in the mind." <em>Psychological inquiry</em> 13.4 (2002): 249-275.</a></li>
			</ol>
		`,
	},

	{
		key: "healthy-mind",
		group: "staff",
		default_hVar: "agegroup",
		surveys: ["school-staff-resilience"],
		title: "Patient Health Questionnaire (4 item)",
		isNew: false,
		firstYear: 2019,
		classes: [
			{
				id: 4,
				label: "Normal",
				colour: "#199750",
			},
			{
				id: 3,
				label: "Mild",
				colour: "#66bc62",
			},
			{
				id: 2,
				label: "Moderate",
				colour: "#f36c42",
			},
			{
				id: 1,
				label: "Severe",
				colour: "#d62f26",
			},
		],
		threshold: 3,
		classifier: function (x) {
			if (x <= 2) {
				return 4;
			} else if (x <= 5) {
				return 3;
			} else if (x <= 8) {
				return 2;
			} else {
				return 1;
			}
		},
		what_does_this_report_show: `
			<p>This graph shows the percentage of staff who exhibit (to varying degrees) symptoms of anxiety and depression according to the healthy-mind - a validated, four-question screening tool.</p>
		`,
		purpose: `
			<p>To allow you to track the prevalence of anxiety and depression among your staff.</p>
		`,
		ask_yourself: `
			<ul>
				<li>What percentage of our staff are likely to self-report symptoms of anxiety and depression?</li>
				<li>How does the prevalence of anxiety or depressive symptoms differ between age groups?</li>
				<li>How has the percentage of staff who experience anxiety and depression changed over time?</li>
				<li>What do you think is the reason for the variance in your data?</li>
			</ul>
		`,
		where_do_these_numbers_come_from: `
			<h6>Raw Score</h6>
			<p>The group into which a respondent is classified (one of 'Normal', 'Mild', 'Moderate' or 'Severe') is determined by their raw score on the shortened Patient Health Questionnaire scale, or healthy-mind, which consists of the following questions.
			<ul class="questions">
				<li>Over the last 2 weeks, how often have you been bothered by the following problems?
					<ul>
						<li><a href="../ph1/"><span class="muted">ph1</span> - Feeling nervous, anxious, or on edge.</a></li>
						<li><a href="../ph2/"><span class="muted">ph2</span> - Not being able to stop or control worrying.</a></li>
						<li><a href="../ph3/"><span class="muted">ph3</span> - Feeling down, depressed or hopeless.</a></li>
						<li><a href="../ph4/"><span class="muted">ph4</span> - Little interest or pleasure in doing things.</a></li>
					</ul>
				</li>
			</ul>
			<p>All four questions use the same Likert response scale. For the purposes of computing the overall anxiety and depression score, responses are encoded as follows:</p>
			<table>
				<tr><th>Response anchor</th><th>Numerical Encoding</th></tr>
				<tr><td>Not at all</td><td>0</td></tr>
				<tr><td>Several days</td><td>1</td></tr>
				<tr><td>More than half the days</td><td>2</td></tr>
				<tr><td>Nearly every day</td><td>3</td></tr>
			</table>
			<p>The healthy-mind score is then calculated by</p>
			<p>${math(
				`\\texttt{healthy-mind} := \\texttt{ph1} + \\texttt{ph2} + \\texttt{ph3} + \\texttt{ph4}`,
				true
			)}</p>
			<p>giving a score between ${math(0)} and ${math(12)}, inclusive.</p>

			<h6>Thresholds</h6>
			<p>Literature on the healthy-mind (see the article linked below) suggests classifying respondents' symptoms as 'Normal' if ${math(
				`0\\leq\\texttt{healthy-mind}\\leq2`
			)}, 'Mild' if ${math(
			`3\\leq\\texttt{healthy-mind}\\leq5`
		)}, 'Moderate' if ${math(
			`6\\leq\\texttt{healthy-mind}\\leq8`
		)} and 'Severe' if ${math(
			`9\\leq\\texttt{healthy-mind}\\leq12`
		)}. These groupings align the PHQ-2 with the results of longer instruments such as the GAD-7 or PHQ-9.

			<h6>Relevant Literature</h6>
			<ol class="literature">
				<li><a href="https://www.sciencedirect.com/science/article/pii/S0033318209708643">Kroenke, Kurt, et al. "An ultra-brief screening scale for anxiety and depression: the PHQ–4." <em>Psychosomatics</em> 50.6 (2009): 613-621.</a></li>
			</ol>
		`,
	},
	{
		key: "PHQ-2",
		group: "staff",
		default_hVar: "agegroup",
		surveys: ["school-staff-resilience"],
		title: "Patient Health Questionnaire (2 item)",
		isNew: false,
		firstYear: 2019,
		classes: [
			{
				id: 2,
				label: "Not Depressed",
				colour: "#66bc62",
			},
			{
				id: 1,
				label: "Depressed",
				colour: "#f36c42",
			},
		],
		threshold: 2,
		classifier: (x) => (x >= 3 ? 1 : 2),
		what_does_this_report_show: `
			<p>This graph shows the percentage of staff who exhibit symptoms of depression according to the PHQ-2 - a validated, two-question screening tool.</p>
		`,
		purpose: `
			<p>To allow you to track the prevalence of depressive symptoms among your staff.</p>
		`,
		ask_yourself: `
			<ul>
				<li>What percentage of our staff self-report symptoms of depression?</li>
				<li>How does the prevalence of depressive symptoms differ between age groups?</li>
				<li>How has the percentage of staff who experience depression changed over time?</li>
				<li>What do you think is the reason for the variance in your data?</li>
			</ul>
		`,
		where_do_these_numbers_come_from: `
			<h6>Raw Score</h6>
			<p>The group into which a respondent is classified (either 'Depressed' or 'Not Depressed') is determined by their raw score on the shortened Patient Health Questionnaire scale, or PHQ-2, which consists of the following questions.
			<ul class="questions">
				<li>Over the last 2 weeks, how often have you been bothered by the following problems?
					<ul>
						<li><a href="../ph3/"><span class="muted">ph3</span> - Feeling down, depressed or hopeless.</a></li>
						<li><a href="../ph4/"><span class="muted">ph4</span> - Little interest or pleasure in doing things.</a></li>
					</ul>
				</li>
			</ul>
			<p>Both questions use the same Likert response scale. For the purposes of computing the overall depression score, responses are encoded as follows:</p>
			<table>
				<tr><th>Response anchor</th><th>Numerical Encoding</th></tr>
				<tr><td>Not at all</td><td>0</td></tr>
				<tr><td>Several days</td><td>1</td></tr>
				<tr><td>More than half the days</td><td>2</td></tr>
				<tr><td>Nearly every day</td><td>3</td></tr>
			</table>
			<p>The PHQ-2 score is then calculated by</p>
			<p>${math(`\\texttt{PHQ-2} := \\texttt{ph3} + \\texttt{ph4}`, true)}</p>
			<p>giving a score between ${math(0)} and ${math(6)}, inclusive.</p>

			<h6>Threshold</h6>
			<p>Literature on the PHQ-2 (see the articles linked below) suggests that a threshold of ${math(
				3
			)} provides acceptable sensitivity, specificity and discriminant validity to be used as a measure of depressive disorders. As such, we classify respondents as 'Depressed' if ${math(
			`\\texttt{PHQ-2}\\geq 3`
		)}, and 'Not Depressed' otherwise ${math(`\\texttt{PHQ-2}< 3`)}.</p>

			<h6>Relevant Literature</h6>
			<ol class="literature">
				<li><a href="https://www-cambridge-org.ezp.lib.unimelb.edu.au/core/services/aop-cambridge-core/content/view/A2083386E3287C1F982BCC82DCEA1CB6/S2056472400001265a.pdf/case_finding_and_screening_clinical_utility_of_the_patient_health_questionnaire_phq9_and_phq2_for_depression_in_primary_care_a_diagnostic_metaanalysis_of_40_studies.pdf">Mitchell, Alex J., et al. "Case finding and screening clinical utility of the Patient Health Questionnaire (PHQ-9 and PHQ-2) for depression in primary care: a diagnostic meta-analysis of 40 studies." <em>BJPsych open</em> 2.2 (2016): 127-138.</a></li>
				<li><a href="https://www.sciencedirect.com/science/article/abs/pii/S0022399904006361">Löwe, Bernd, Kurt Kroenke, and Kerstin Gräfe. "Detecting and monitoring depression with a two-item questionnaire (PHQ-2)." <em>Journal of psychosomatic research</em> 58.2 (2005): 163-171.</a></li>
				<li><a href="https://www.jstor.org/stable/3768417">Kroenke, Kurt, Robert L. Spitzer, and Janet BW Williams. "The Patient Health Questionnaire-2: validity of a two-item depression screener." <em>Medical care</em> (2003): 1284-1292.</a></li>
				<li><a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3100798/">Richardson, Laura P., et al. "Evaluation of the PHQ-2 as a brief screen for detecting major depression among adolescents." <em>Pediatrics</em> 125.5 (2010): e1097-e1103.</a></li>
				<li><a href="http://www.annfammed.org/content/8/4/348.long">Arroll, Bruce, et al. "Validation of PHQ-2 and PHQ-9 to screen for major depression in the primary care population." <em>The Annals of Family Medicine</em> 8.4 (2010): 348-353.</a></li>
				<li><a href="https://www.sciencedirect.com/science/article/pii/S0163834318303712?via%3Dihub">Staples, Lauren G., et al. "Psychometric properties and clinical utility of brief measures of depression, anxiety, and general distress: The PHQ-2, GAD-2, and K-6." <em>General hospital psychiatry</em> 56 (2019): 13-18.</a></li>
			</ol>
		`,
	},
	{
		key: "GAD-2",
		group: "staff",
		default_hVar: "agegroup",
		surveys: ["school-staff-resilience"],
		title: "Generalized Anxiety Disorder scale (2 item)",
		isNew: false,
		firstYear: 2019,
		classes: [
			{
				id: 2,
				label: "Not Anxious",
				colour: "#66bc62",
			},
			{
				id: 1,
				label: "Anxious",
				colour: "#f36c42",
			},
		],
		threshold: 2,
		classifier: (x) => (x >= 3 ? 1 : 2),
		what_does_this_report_show: `
			<p>This graph shows the percentage of staff who exhibit symptoms of anxiety according to the GAD-2 - a validated, two-question screening tool.</p>
		`,
		purpose: `
			<p>To allow you to track the prevalence of anxiety among your staff.</p>
		`,
		ask_yourself: `
			<ul>
				<li>What percentage of our staff self-report symptoms of anxiety?</li>
				<li>How do levels of anxiety differ between age groups?</li>
				<li>How has the percentage of staff who are anxious changed over time?</li>
				<li>What do you think is the reason for the variance in your data?</li>
			</ul>
		`,
		where_do_these_numbers_come_from: `
			<h6>Raw Score</h6>
			<p>The group into which a respondent is classified (either 'Anxious' or 'Not Anxious') is determined by their raw score on the shortened General Anxiety Disorder scale, or GAD-2, which consists of the following questions.
			<ul class="questions">
				<li>Over the last 2 weeks, how often have you been bothered by the following problems?
					<ul>
						<li><a href="../ph1/"><span class="muted">ph1</span> - Feeling nervous, anxious, or on edge.</a></li>
						<li><a href="../ph2"><span class="muted">ph2</span> - Not being able to stop or control worrying.</a></li>
					</ul>
				</li>
			</ul>
			<p>Both questions use the same Likert response scale. For the purposes of computing the overall anxiety score, responses are encoded as follows:</p>
			<table>
				<tr><th>Response anchor</th><th>Numerical Encoding</th></tr>
				<tr><td>Not at all</td><td>0</td></tr>
				<tr><td>Several days</td><td>1</td></tr>
				<tr><td>More than half the days</td><td>2</td></tr>
				<tr><td>Nearly every day</td><td>3</td></tr>
			</table>
			<p>The GAD-2 score is then calculated by</p>
			<p>${math(`\\texttt{GAD-2} := \\texttt{ph1} + \\texttt{ph2}`, true)}</p>
			<p>giving a score between ${math(0)} and ${math(6)}, inclusive.</p>

			<h6>Threshold</h6>
			<p>Literature on the GAD-2 (see the articles linked below) suggests that a threshold of ${math(
				3
			)} provides acceptable sensitivity, specificity and discriminant validity to be used as a measure of anxiety disorders and general stress. As such, we classify respondents as 'Anxious' if ${math(
			`\\texttt{GAD-2}\\geq 3`
		)}, and 'Not Anxious' otherwise ${math(`\\texttt{GAD-2}< 3`)}.</p>

			<h6>Relevant Literature</h6>
			<ol class="literature">
				<li><a href="https://s3.amazonaws.com/academia.edu.documents/41377550/1-s2.0-S0163834315002406-main.pdf?response-content-disposition=inline%3B%20filename%3DScreening_for_anxiety_disorders_with_the.pdf&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIATUSBJ6BALQOGIJB4%2F20200428%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20200428T231700Z&X-Amz-Expires=3600&X-Amz-Security-Token=IQoJb3JpZ2luX2VjELf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIE1FK6RxfDigp%2BdfMasvjgaGuw2KEN2bJKKwVOWXzBADAiEA%2F0pyV1hXhf1MSDdYgvZm2NoDc6GiFuRf7lTJPiRsrsUqvQMI4P%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAAGgwyNTAzMTg4MTEyMDAiDBBFukGo%2BIYb9cKRFCqRA3lRioiCa1q7OYYucTWmC7exOFwGrmxd680wDCGMzLL0hL02SCVcpRVJbgWTGkqK0RM0Fnc3YLvp8Ql8kpeJncVpmIs8vLv4NkeoG38Mlvn%2Bj%2BCrdhjLN9QiF8voDoI3hcKHHwnQY%2F2UMS%2FZpSfsWgVUO%2B51ThUvVgP6fO3EcVMg6T7eizdielfcpeX5fWDaDPBClJQuM5ZDmN1SYIRAeX2FMpTauKyT9tx%2F30pqQLj2mM1qbWdjoDTCzKX7GSoC4URQeYEBgD7jxauDklexacAGSIs2tsEK9Ein9Qv%2F7O%2FVFqj79IJtP575UrsUe19wD6LeWKJ6f7VV7MtQiUTD%2FL9a7WUvobgWkDUHI2oNcHgM4oPfH08EgGeKlEStVSDddY4u3g2k6H9R2DR3muX8dQiALVcIWaN2b0wL5A%2B4zp5NnOfKNbXPuV9dPOnGelZElCoVfkPgaBWtckUOhA5KIEO6mXk1BHnStrIHN1umQnZhNQ9TsWi9cGI9%2BsvKPxw6SVLaoqZwilqQNStMnLjL56ePMNXqovUFOusBOu9PYb8I56ugePDCxA%2FVDtTEwt49JwdECHFVjQYpkimD9enu2e%2BSfUIznZlLpF%2FdYHJ65FNrGF32kN0Tif4zWgdnEAbjC26tzitvGweYczOy%2FvE0gRw7znBLKKf5vsEIDDr7euj9HhoWq9eE0gc9PosRboycFoojiEpAL0pRE3N8ZNTqEOJrOpNNeAQ1ZQQKvPp%2BX99te781K8HD%2FJ79KoeyhywFG3HpB%2BpefNg%2BmQ3d1VlazsDgeNk0IVO8lWlkjadV8B4NGSJbgCY6coZh1YQzRb6pqqomm%2Fumt9pUPJfpOCn18Ne2YfT1tA%3D%3D&X-Amz-SignedHeaders=host&X-Amz-Signature=44f443f777c4972aacf83379d1189f868468bfa3320e9fdcffed49b23a21842a">Plummer, Faye, et al. "Screening for anxiety disorders with the GAD-7 and GAD-2: a systematic review and diagnostic metaanalysis." <em>General hospital psychiatry</em> 39 (2016): 24-31.</a></li>
				<li><a href="https://www.sciencedirect.com/science/article/pii/S0163834318303712?via%3Dihub">Staples, Lauren G., et al. "Psychometric properties and clinical utility of brief measures of depression, anxiety, and general distress: The PHQ-2, GAD-2, and K-6." <em>General hospital psychiatry</em> 56 (2019): 13-18.</a></li>
			</ol>
		`,
	},

	{
		key: "cantril",
		group: "employee",
		default_hVar: "agegroup",
		surveys: ["workplace-resilience"],
		title: "Life Satisfaction",
		isNew: false,
		firstYear: 2019,
		classes: [
			{
				id: 8,
				label: "8 (best)",
				colour: "#388e3c",
			},
			{
				id: 7,
				label: "7",
				colour: "#43a047",
			},
			{
				id: 6,
				label: "6",
				colour: "#7cb342",
			},
			{
				id: 5,
				label: "5",
				colour: "#c0ca33",
			},
			{
				id: 4,
				label: "4",
				colour: "#fdd835",
			},
			{
				id: 3,
				label: "3",
				colour: "#ffb300",
			},
			{
				id: 2,
				label: "2",
				colour: "#fb8c00",
			},
			{
				id: 1,
				label: "1 (worst)",
				colour: "#f4511e",
			},
		],
		threshold: 5,
		classifier: Number,
		what_does_this_report_show: `
			<p>The question posed to employee was 'Imagine a ladder with steps numbered 1 at the bottom to 8 at the top. The top represents the best possible life for YOU and the bottom represents the worst possible life for YOU. On which step of the ladder do you feel YOU stand at this time?'. This question is an adapted version of the Cantril Ladder, a widely used survey tool for measing life satisfaction. The graph above shows the percentage of employee who identified themselves as being on each rung of the ladder.</p>
		`,
		purpose: `
			<p>To allow you to track levels of overall life satisfaction among your employee.</p>
		`,
		ask_yourself: `
			<ul>
				<li>What is the overall distribution of life satisfaction?</li>
				<li>How does life satisfaction differ between genders and age groups?</li>
				<li>How has life satisfaction changed over time?</li>
				<li>What do you think is the reason for the variance in your data?</li>
			</ul>
		`,
		where_do_these_numbers_come_from: `
			<p>Respondents are simply grouped by their answer to the following question, which is an adapted version of the Cantril ladder.
			<ul class="questions">
				<li><span class="muted">cantril</span> - Imagine a ladder with steps numbered 1 at the bottom to 8 at the top. The top represents the best possible life for YOU and the bottom represents the worst possible life for YOU. On which step of the ladder do you feel YOU stand at this time?</li>
			</ul>
			<p>The graph above simply plots the percentage of respondents who self-identified as being on each of the eight rungs of the ladder.</p>

			<p>Note that this version of the Cantril ladder differs slightly from versions used elsewhere in that the wording has been simplified and we use an 8-point (1-8), rather than a 11-point (0-10) scale.</p>
		`,
	},

	{
		key: "cantril2",
		group: "employee",
		default_hVar: "agegroup",
		surveys: ["workplace-resilience"],
		title: "Job Satisfaction",
		isNew: false,
		firstYear: 2019,
		classes: [
			{
				id: 8,
				label: "8 (best)",
				colour: "#388e3c",
			},
			{
				id: 7,
				label: "7",
				colour: "#43a047",
			},
			{
				id: 6,
				label: "6",
				colour: "#7cb342",
			},
			{
				id: 5,
				label: "5",
				colour: "#c0ca33",
			},
			{
				id: 4,
				label: "4",
				colour: "#fdd835",
			},
			{
				id: 3,
				label: "3",
				colour: "#ffb300",
			},
			{
				id: 2,
				label: "2",
				colour: "#fb8c00",
			},
			{
				id: 1,
				label: "1 (worst)",
				colour: "#f4511e",
			},
		],
		threshold: 5,
		classifier: Number,
		what_does_this_report_show: `
			<p>The question posed to employee was 'Imagine a ladder with steps numbered 1 at the bottom to 8 at the top. The top represents very satisfied with YOUR JOB and the bottom represents very dissatisfied with YOUR JOB. On which step of the ladder do you feel you stand at this time?'. This question is an adapted version of the Cantril Ladder, a widely used survey tool for measing life satisfaction. The graph above shows the percentage of employee who identified themselves as being on each rung of the ladder.</p>
		`,
		purpose: `
			<p>To allow you to track levels of overall job satisfaction among your employee.</p>
		`,
		ask_yourself: `
			<ul>
				<li>What is the overall distribution of job satisfaction?</li>
				<li>How does job satisfaction differ between genders and age groups?</li>
				<li>How has job satisfaction changed over time?</li>
				<li>What do you think is the reason for the variance in your data?</li>
			</ul>
		`,
		where_do_these_numbers_come_from: `
			<p>Respondents are simply grouped by their answer to the following question, which is an adapted version of the Cantril ladder.
			<ul class="questions">
				<li><span class="muted">cantril2</span> - Imagine a ladder with steps numbered 1 at the bottom to 8 at the top. The top represents very satisfied with YOUR JOB and the bottom represents very dissatisfied with YOUR JOB. On which step of the ladder do you feel you stand at this time?</li>
			</ul>
			<p>The graph above simply plots the percentage of respondents who self-identified as being on each of the eight rungs of the ladder.</p>

			<p>Note that this version of the Cantril ladder differs slightly from versions used elsewhere in that the wording has been simplified, it asks about job (rather than life) satisfaction, and we use an 8-point (1-8), rather than a 11-point (0-10) scale.</p>
		`,
	},

	{
		key: "cantril3",
		group: "employee",
		default_hVar: "agegroup",
		surveys: ["workplace-resilience"],
		title: "Workplace Satisfaction",
		isNew: false,
		firstYear: 2019,
		classes: [
			{
				id: 8,
				label: "8 (best)",
				colour: "#388e3c",
			},
			{
				id: 7,
				label: "7",
				colour: "#43a047",
			},
			{
				id: 6,
				label: "6",
				colour: "#7cb342",
			},
			{
				id: 5,
				label: "5",
				colour: "#c0ca33",
			},
			{
				id: 4,
				label: "4",
				colour: "#fdd835",
			},
			{
				id: 3,
				label: "3",
				colour: "#ffb300",
			},
			{
				id: 2,
				label: "2",
				colour: "#fb8c00",
			},
			{
				id: 1,
				label: "1 (worst)",
				colour: "#f4511e",
			},
		],
		threshold: 5,
		classifier: Number,
		what_does_this_report_show: `
			<p>The question posed to employee was 'Imagine a ladder with steps numbered 1 at the bottom to 8 at the top. The top represents very satisfied with YOUR WORKPLACE and the bottom represents very dissatisfied with YOUR WORKPLACE. On which step of the ladder do you feel you stand at this time?'. This question is an adapted version of the Cantril Ladder, a widely used survey tool for measing life satisfaction. The graph above shows the percentage of employee who identified themselves as being on each rung of the ladder.</p>
		`,
		purpose: `
			<p>To allow you to track levels of overall workplace satisfaction among your employee.</p>
		`,
		ask_yourself: `
			<ul>
				<li>What is the overall distribution of workplace satisfaction?</li>
				<li>How does workplace satisfaction differ between genders and age groups?</li>
				<li>How has workplace satisfaction changed over time?</li>
				<li>What do you think is the reason for the variance in your data?</li>
			</ul>
		`,
		where_do_these_numbers_come_from: `
			<p>Respondents are simply grouped by their answer to the following question, which is an adapted version of the Cantril ladder.
			<ul class="questions">
				<li><span class="muted">cantril3</span> - Imagine a ladder with steps numbered 1 at the bottom to 8 at the top. The top represents very satisfied with YOUR WORKPLACE and the bottom represents very dissatisfied with YOUR WORKPLACE. On which step of the ladder do you feel you stand at this time?</li>
			</ul>
			<p>The graph above simply plots the percentage of respondents who self-identified as being on each of the eight rungs of the ladder.</p>

			<p>Note that this version of the Cantril ladder differs slightly from versions used elsewhere in that the wording has been simplified, it asks about workplace (rather than life) satisfaction, and we use an 8-point (1-8), rather than a 11-point (0-10) scale.</p>
		`,
	},

	{
		key: "AHS",
		group: "employee",
		default_hVar: "agegroup",
		surveys: ["workplace-resilience"],
		title: "Adult Hope Scale",
		isNew: true,
		firstYear: 2020,
		classes: [
			{
				id: 6,
				label: "All",
				colour: "#43a047",
			},
			{
				id: 5,
				label: "Most",
				colour: "#7cb342",
			},
			{
				id: 4,
				label: "Lots",
				colour: "#c0ca33",
			},
			{
				id: 3,
				label: "Some",
				colour: "#fdd835",
			},
			{
				id: 2,
				label: "Little",
				colour: "#ffb300",
			},
			{
				id: 1,
				label: "None",
				colour: "#f4511e",
			},
		],
		threshold: 4,
		classifier: function (x) {
			if (x >= 15) {
				return 6;
			} else if (x >= 13) {
				return 5;
			} else if (x >= 10) {
				return 4;
			} else if (x >= 8) {
				return 3;
			} else if (x >= 6) {
				return 2;
			} else {
				return 1;
			}
		},
		what_does_this_report_show: `
			<p>This graph shows the percentage of employee who exhibit varying degrees of hope according to a simplified version of the Adult Hope Scale (AHS), a widely used pyschometric tool for measuring hope.</p>
		`,
		purpose: `
			<p>To allow you to track levels of hope among your employee.</p>
		`,
		ask_yourself: `
			<ul>
				<li>What are the levels of hope overall?</li>
				<li>How do levels of hope differ between genders and age groups?</li>
				<li>How have levels of hope changed over time?</li>
				<li>What do you think is the reason for the variance in your data?</li>
			</ul>
		`,
		where_do_these_numbers_come_from: `
			<h6>Raw Score</h6>
			<p>The group into which a respondent is classified (one of 'All', 'Most', 'Lots', 'Some', 'Little' hope or 'None') is determined by their raw score on an adpated version of the Adult Hope Scale, or AHS, which consists of the following questions.
			<ul class="questions">
				<li><a href="../ahs1/?sm=m-hope-employee,m-AHS-items-employee&mp=workplace-resilience-interactive"><span class="muted">ahs1</span> - I can think of many ways to get out of a bad situation.</a></li>
				<li><a href="../ahs3/?sm=m-hope-employee,m-AHS-items-employee&mp=workplace-resilience-interactive"><span class="muted">ahs3</span> - I believe there are lots of ways around any problem.</a></li>
				<li><a href="../ahs4/?sm=m-hope-employee,m-AHS-items-employee&mp=workplace-resilience-interactive"><span class="muted">ahs4</span> - I can think of many ways to get the things in life that are most important to me.</a></li>
				<li><a href="../ahs5/?sm=m-hope-employee,m-AHS-items-employee&mp=workplace-resilience-interactive"><span class="muted">ahs5</span> - Even when others get discouraged, I know I can find a way to solve the problem.</a></li>
			</ul>
			<p>All four questions use the same Likert response scale. For the purposes of computing the overall hope score, responses are encoded as follows:</p>
			<table>
				<tr><th>Response anchor</th><th>Numerical Encoding</th></tr>
				<tr><td>Never or Rarely</td><td>1</td></tr>
				<tr><td>Sometimes</td><td>2</td></tr>
				<tr><td>Often</td><td>3</td></tr>
				<tr><td>Always or Almost Always</td><td>4</td></tr>
			</table>
			<p>The overall AHS hope score is then calculated by</p>
			<p>${math(
				`\\texttt{hope} := \\texttt{ahs1} + \\texttt{ahs3} + \\texttt{ahs4} + \\texttt{ahs5}`,
				true
			)}</p>
			<p>giving a score between ${math(4)} and ${math(16)}, inclusive.</p>

			<h6>Thresholds</h6>
			<p>In the literature, the AHS is usually reported as a continuous score, rather than being used to classify respondents into groups according to their reported levels of hope. For ease of communication, we report equivalent groups to those sometimes used in the Children\'s Hope Scale (CHS). We classify respondents' overall levels of hope as 'All' if ${math(
				`\\texttt{hope}\\geq15`
			)}, 'Most' if ${math(`13\\leq\\texttt{hope}<15`)}, 'Lots' if ${math(
			`10\\leq\\texttt{hope}<13`
		)}, 'Some' if ${math(`8\\leq\\texttt{hope}<10`)}, 'Little' if ${math(
			`6\\leq\\texttt{hope}<8`
		)} and 'None' if ${math(`\\texttt{hope}<6`)}.

			<h6>Relevant Literature</h6>
			<ol class="literature">
				<li><a href="https://psycnet.apa.org/buy/1991-17270-001">Snyder, Charles R., et al. "The will and the ways: development and validation of an individual-differences measure of hope." <em>Journal of personality and social psychology</em> 60.4 (1991): 570.</a></li>
				<li><a href="https://www.tandfonline.com/doi/abs/10.1207/S15327965PLI1304_01">Snyder, C. Richard. "Hope theory: Rainbows in the mind." <em>Psychological inquiry</em> 13.4 (2002): 249-275.</a></li>
			</ol>
		`,
	},

	{
		key: "healthy-mind",
		group: "employee",
		default_hVar: "agegroup",
		surveys: ["workplace-resilience"],
		title: "Patient Health Questionnaire (4 item)",
		isNew: false,
		firstYear: 2019,
		classes: [
			{
				id: 4,
				label: "Normal",
				colour: "#199750",
			},
			{
				id: 3,
				label: "Mild",
				colour: "#66bc62",
			},
			{
				id: 2,
				label: "Moderate",
				colour: "#f36c42",
			},
			{
				id: 1,
				label: "Severe",
				colour: "#d62f26",
			},
		],
		threshold: 3,
		classifier: function (x) {
			if (x <= 2) {
				return 4;
			} else if (x <= 5) {
				return 3;
			} else if (x <= 8) {
				return 2;
			} else {
				return 1;
			}
		},
		what_does_this_report_show: `
			<p>This graph shows the percentage of employee who exhibit (to varying degrees) symptoms of anxiety and depression according to the healthy-mind - a validated, four-question screening tool.</p>
		`,
		purpose: `
			<p>To allow you to track the prevalence of anxiety and depression among your employee.</p>
		`,
		ask_yourself: `
			<ul>
				<li>What percentage of our employee are likely to self-report symptoms of anxiety and depression?</li>
				<li>How does the prevalence of anxiety or depressive symptoms differ between age groups?</li>
				<li>How has the percentage of employee who experience anxiety and depression changed over time?</li>
				<li>What do you think is the reason for the variance in your data?</li>
			</ul>
		`,
		where_do_these_numbers_come_from: `
			<h6>Raw Score</h6>
			<p>The group into which a respondent is classified (one of 'Normal', 'Mild', 'Moderate' or 'Severe') is determined by their raw score on the shortened Patient Health Questionnaire scale, or healthy-mind, which consists of the following questions.
			<ul class="questions">
				<li>Over the last 2 weeks, how often have you been bothered by the following problems?
					<ul>
						<li><a href="../ph1/"><span class="muted">ph1</span> - Feeling nervous, anxious, or on edge.</a></li>
						<li><a href="../ph2/"><span class="muted">ph2</span> - Not being able to stop or control worrying.</a></li>
						<li><a href="../ph3/"><span class="muted">ph3</span> - Feeling down, depressed or hopeless.</a></li>
						<li><a href="../ph4/"><span class="muted">ph4</span> - Little interest or pleasure in doing things.</a></li>
					</ul>
				</li>
			</ul>
			<p>All four questions use the same Likert response scale. For the purposes of computing the overall anxiety and depression score, responses are encoded as follows:</p>
			<table>
				<tr><th>Response anchor</th><th>Numerical Encoding</th></tr>
				<tr><td>Not at all</td><td>0</td></tr>
				<tr><td>Several days</td><td>1</td></tr>
				<tr><td>More than half the days</td><td>2</td></tr>
				<tr><td>Nearly every day</td><td>3</td></tr>
			</table>
			<p>The healthy-mind score is then calculated by</p>
			<p>${math(
				`\\texttt{healthy-mind} := \\texttt{ph1} + \\texttt{ph2} + \\texttt{ph3} + \\texttt{ph4}`,
				true
			)}</p>
			<p>giving a score between ${math(0)} and ${math(12)}, inclusive.</p>

			<h6>Thresholds</h6>
			<p>Literature on the healthy-mind (see the article linked below) suggests classifying respondents' symptoms as 'Normal' if ${math(
				`0\\leq\\texttt{healthy-mind}\\leq2`
			)}, 'Mild' if ${math(
			`3\\leq\\texttt{healthy-mind}\\leq5`
		)}, 'Moderate' if ${math(
			`6\\leq\\texttt{healthy-mind}\\leq8`
		)} and 'Severe' if ${math(
			`9\\leq\\texttt{healthy-mind}\\leq12`
		)}. These groupings align the PHQ-2 with the results of longer instruments such as the GAD-7 or PHQ-9.

			<h6>Relevant Literature</h6>
			<ol class="literature">
				<li><a href="https://www.sciencedirect.com/science/article/pii/S0033318209708643">Kroenke, Kurt, et al. "An ultra-brief screening scale for anxiety and depression: the PHQ–4." <em>Psychosomatics</em> 50.6 (2009): 613-621.</a></li>
			</ol>
		`,
	},
	{
		key: "PHQ-2",
		group: "employee",
		default_hVar: "agegroup",
		surveys: ["workplace-resilience"],
		title: "Patient Health Questionnaire (2 item)",
		isNew: false,
		firstYear: 2019,
		classes: [
			{
				id: 2,
				label: "Not Depressed",
				colour: "#66bc62",
			},
			{
				id: 1,
				label: "Depressed",
				colour: "#f36c42",
			},
		],
		threshold: 2,
		classifier: (x) => (x >= 3 ? 1 : 2),
		what_does_this_report_show: `
			<p>This graph shows the percentage of employee who exhibit symptoms of depression according to the PHQ-2 - a validated, two-question screening tool.</p>
		`,
		purpose: `
			<p>To allow you to track the prevalence of depressive symptoms among your employee.</p>
		`,
		ask_yourself: `
			<ul>
				<li>What percentage of our employee self-report symptoms of depression?</li>
				<li>How does the prevalence of depressive symptoms differ between age groups?</li>
				<li>How has the percentage of employee who experience depression changed over time?</li>
				<li>What do you think is the reason for the variance in your data?</li>
			</ul>
		`,
		where_do_these_numbers_come_from: `
			<h6>Raw Score</h6>
			<p>The group into which a respondent is classified (either 'Depressed' or 'Not Depressed') is determined by their raw score on the shortened Patient Health Questionnaire scale, or PHQ-2, which consists of the following questions.
			<ul class="questions">
				<li>Over the last 2 weeks, how often have you been bothered by the following problems?
					<ul>
						<li><a href="../ph3/"><span class="muted">ph3</span> - Feeling down, depressed or hopeless.</a></li>
						<li><a href="../ph4/"><span class="muted">ph4</span> - Little interest or pleasure in doing things.</a></li>
					</ul>
				</li>
			</ul>
			<p>Both questions use the same Likert response scale. For the purposes of computing the overall depression score, responses are encoded as follows:</p>
			<table>
				<tr><th>Response anchor</th><th>Numerical Encoding</th></tr>
				<tr><td>Not at all</td><td>0</td></tr>
				<tr><td>Several days</td><td>1</td></tr>
				<tr><td>More than half the days</td><td>2</td></tr>
				<tr><td>Nearly every day</td><td>3</td></tr>
			</table>
			<p>The PHQ-2 score is then calculated by</p>
			<p>${math(`\\texttt{PHQ-2} := \\texttt{ph3} + \\texttt{ph4}`, true)}</p>
			<p>giving a score between ${math(0)} and ${math(6)}, inclusive.</p>

			<h6>Threshold</h6>
			<p>Literature on the PHQ-2 (see the articles linked below) suggests that a threshold of ${math(
				3
			)} provides acceptable sensitivity, specificity and discriminant validity to be used as a measure of depressive disorders. As such, we classify respondents as 'Depressed' if ${math(
			`\\texttt{PHQ-2}\\geq 3`
		)}, and 'Not Depressed' otherwise ${math(`\\texttt{PHQ-2}< 3`)}.</p>

			<h6>Relevant Literature</h6>
			<ol class="literature">
				<li><a href="https://www-cambridge-org.ezp.lib.unimelb.edu.au/core/services/aop-cambridge-core/content/view/A2083386E3287C1F982BCC82DCEA1CB6/S2056472400001265a.pdf/case_finding_and_screening_clinical_utility_of_the_patient_health_questionnaire_phq9_and_phq2_for_depression_in_primary_care_a_diagnostic_metaanalysis_of_40_studies.pdf">Mitchell, Alex J., et al. "Case finding and screening clinical utility of the Patient Health Questionnaire (PHQ-9 and PHQ-2) for depression in primary care: a diagnostic meta-analysis of 40 studies." <em>BJPsych open</em> 2.2 (2016): 127-138.</a></li>
				<li><a href="https://www.sciencedirect.com/science/article/abs/pii/S0022399904006361">Löwe, Bernd, Kurt Kroenke, and Kerstin Gräfe. "Detecting and monitoring depression with a two-item questionnaire (PHQ-2)." <em>Journal of psychosomatic research</em> 58.2 (2005): 163-171.</a></li>
				<li><a href="https://www.jstor.org/stable/3768417">Kroenke, Kurt, Robert L. Spitzer, and Janet BW Williams. "The Patient Health Questionnaire-2: validity of a two-item depression screener." <em>Medical care</em> (2003): 1284-1292.</a></li>
				<li><a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3100798/">Richardson, Laura P., et al. "Evaluation of the PHQ-2 as a brief screen for detecting major depression among adolescents." <em>Pediatrics</em> 125.5 (2010): e1097-e1103.</a></li>
				<li><a href="http://www.annfammed.org/content/8/4/348.long">Arroll, Bruce, et al. "Validation of PHQ-2 and PHQ-9 to screen for major depression in the primary care population." <em>The Annals of Family Medicine</em> 8.4 (2010): 348-353.</a></li>
				<li><a href="https://www.sciencedirect.com/science/article/pii/S0163834318303712?via%3Dihub">Staples, Lauren G., et al. "Psychometric properties and clinical utility of brief measures of depression, anxiety, and general distress: The PHQ-2, GAD-2, and K-6." <em>General hospital psychiatry</em> 56 (2019): 13-18.</a></li>
			</ol>
		`,
	},
	{
		key: "GAD-2",
		group: "employee",
		default_hVar: "agegroup",
		surveys: ["workplace-resilience"],
		title: "Generalized Anxiety Disorder scale (2 item)",
		isNew: false,
		firstYear: 2019,
		classes: [
			{
				id: 2,
				label: "Not Anxious",
				colour: "#66bc62",
			},
			{
				id: 1,
				label: "Anxious",
				colour: "#f36c42",
			},
		],
		threshold: 2,
		classifier: (x) => (x >= 3 ? 1 : 2),
		what_does_this_report_show: `
			<p>This graph shows the percentage of employee who exhibit symptoms of anxiety according to the GAD-2 - a validated, two-question screening tool.</p>
		`,
		purpose: `
			<p>To allow you to track the prevalence of anxiety among your employee.</p>
		`,
		ask_yourself: `
			<ul>
				<li>What percentage of our employee self-report symptoms of anxiety?</li>
				<li>How do levels of anxiety differ between age groups?</li>
				<li>How has the percentage of employee who are anxious changed over time?</li>
				<li>What do you think is the reason for the variance in your data?</li>
			</ul>
		`,
		where_do_these_numbers_come_from: `
			<h6>Raw Score</h6>
			<p>The group into which a respondent is classified (either 'Anxious' or 'Not Anxious') is determined by their raw score on the shortened General Anxiety Disorder scale, or GAD-2, which consists of the following questions.
			<ul class="questions">
				<li>Over the last 2 weeks, how often have you been bothered by the following problems?
					<ul>
						<li><a href="../ph1/"><span class="muted">ph1</span> - Feeling nervous, anxious, or on edge.</a></li>
						<li><a href="../ph2"><span class="muted">ph2</span> - Not being able to stop or control worrying.</a></li>
					</ul>
				</li>
			</ul>
			<p>Both questions use the same Likert response scale. For the purposes of computing the overall anxiety score, responses are encoded as follows:</p>
			<table>
				<tr><th>Response anchor</th><th>Numerical Encoding</th></tr>
				<tr><td>Not at all</td><td>0</td></tr>
				<tr><td>Several days</td><td>1</td></tr>
				<tr><td>More than half the days</td><td>2</td></tr>
				<tr><td>Nearly every day</td><td>3</td></tr>
			</table>
			<p>The GAD-2 score is then calculated by</p>
			<p>${math(`\\texttt{GAD-2} := \\texttt{ph1} + \\texttt{ph2}`, true)}</p>
			<p>giving a score between ${math(0)} and ${math(6)}, inclusive.</p>

			<h6>Threshold</h6>
			<p>Literature on the GAD-2 (see the articles linked below) suggests that a threshold of ${math(
				3
			)} provides acceptable sensitivity, specificity and discriminant validity to be used as a measure of anxiety disorders and general stress. As such, we classify respondents as 'Anxious' if ${math(
			`\\texttt{GAD-2}\\geq 3`
		)}, and 'Not Anxious' otherwise ${math(`\\texttt{GAD-2}< 3`)}.</p>

			<h6>Relevant Literature</h6>
			<ol class="literature">
				<li><a href="https://s3.amazonaws.com/academia.edu.documents/41377550/1-s2.0-S0163834315002406-main.pdf?response-content-disposition=inline%3B%20filename%3DScreening_for_anxiety_disorders_with_the.pdf&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIATUSBJ6BALQOGIJB4%2F20200428%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20200428T231700Z&X-Amz-Expires=3600&X-Amz-Security-Token=IQoJb3JpZ2luX2VjELf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIE1FK6RxfDigp%2BdfMasvjgaGuw2KEN2bJKKwVOWXzBADAiEA%2F0pyV1hXhf1MSDdYgvZm2NoDc6GiFuRf7lTJPiRsrsUqvQMI4P%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAAGgwyNTAzMTg4MTEyMDAiDBBFukGo%2BIYb9cKRFCqRA3lRioiCa1q7OYYucTWmC7exOFwGrmxd680wDCGMzLL0hL02SCVcpRVJbgWTGkqK0RM0Fnc3YLvp8Ql8kpeJncVpmIs8vLv4NkeoG38Mlvn%2Bj%2BCrdhjLN9QiF8voDoI3hcKHHwnQY%2F2UMS%2FZpSfsWgVUO%2B51ThUvVgP6fO3EcVMg6T7eizdielfcpeX5fWDaDPBClJQuM5ZDmN1SYIRAeX2FMpTauKyT9tx%2F30pqQLj2mM1qbWdjoDTCzKX7GSoC4URQeYEBgD7jxauDklexacAGSIs2tsEK9Ein9Qv%2F7O%2FVFqj79IJtP575UrsUe19wD6LeWKJ6f7VV7MtQiUTD%2FL9a7WUvobgWkDUHI2oNcHgM4oPfH08EgGeKlEStVSDddY4u3g2k6H9R2DR3muX8dQiALVcIWaN2b0wL5A%2B4zp5NnOfKNbXPuV9dPOnGelZElCoVfkPgaBWtckUOhA5KIEO6mXk1BHnStrIHN1umQnZhNQ9TsWi9cGI9%2BsvKPxw6SVLaoqZwilqQNStMnLjL56ePMNXqovUFOusBOu9PYb8I56ugePDCxA%2FVDtTEwt49JwdECHFVjQYpkimD9enu2e%2BSfUIznZlLpF%2FdYHJ65FNrGF32kN0Tif4zWgdnEAbjC26tzitvGweYczOy%2FvE0gRw7znBLKKf5vsEIDDr7euj9HhoWq9eE0gc9PosRboycFoojiEpAL0pRE3N8ZNTqEOJrOpNNeAQ1ZQQKvPp%2BX99te781K8HD%2FJ79KoeyhywFG3HpB%2BpefNg%2BmQ3d1VlazsDgeNk0IVO8lWlkjadV8B4NGSJbgCY6coZh1YQzRb6pqqomm%2Fumt9pUPJfpOCn18Ne2YfT1tA%3D%3D&X-Amz-SignedHeaders=host&X-Amz-Signature=44f443f777c4972aacf83379d1189f868468bfa3320e9fdcffed49b23a21842a">Plummer, Faye, et al. "Screening for anxiety disorders with the GAD-7 and GAD-2: a systematic review and diagnostic metaanalysis." <em>General hospital psychiatry</em> 39 (2016): 24-31.</a></li>
				<li><a href="https://www.sciencedirect.com/science/article/pii/S0163834318303712?via%3Dihub">Staples, Lauren G., et al. "Psychometric properties and clinical utility of brief measures of depression, anxiety, and general distress: The PHQ-2, GAD-2, and K-6." <em>General hospital psychiatry</em> 56 (2019): 13-18.</a></li>
			</ol>
		`,
	},

	{
		key: "cantril5",
		group: "parent",
		default_hVar: "grade",
		surveys: ["parent-resilience"],
		title: "Life Satisfaction",
		isNew: false,
		firstYear: 2019,
		classes: [
			{
				id: 8,
				label: "8 (best)",
				colour: "#388e3c",
			},
			{
				id: 7,
				label: "7",
				colour: "#43a047",
			},
			{
				id: 6,
				label: "6",
				colour: "#7cb342",
			},
			{
				id: 5,
				label: "5",
				colour: "#c0ca33",
			},
			{
				id: 4,
				label: "4",
				colour: "#fdd835",
			},
			{
				id: 3,
				label: "3",
				colour: "#ffb300",
			},
			{
				id: 2,
				label: "2",
				colour: "#fb8c00",
			},
			{
				id: 1,
				label: "1 (worst)",
				colour: "#f4511e",
			},
		],
		threshold: 5,
		classifier: Number,
		what_does_this_report_show: `
			<p>The question posed to parents/caregivers  was 'Please see the ladder below. There are 8 steps on this ladder. How satisfied are you currently with your parenting/caregiving life on a scale of 1 to 8? where Step 1. is “very dissatisfied” and Step 8. is “extremely satisfied”?'</p>
		`,
		purpose: `
			<p>To allow you to track levels of overall life satisfaction among your parents/caregivers .</p>
		`,
		ask_yourself: `
			<ul>
				<li>What is the overall distribution of life satisfaction?</li>
				<li>How does life satisfaction differ between genders, year levels and age groups?</li>
				<li>How has life satisfaction changed over time?</li>
				<li>What do you think is the reason for the variance in your data?</li>
			</ul>
		`,
		where_do_these_numbers_come_from: `
			<p>Respondents are simply grouped by their answer to the following question, which is an adapted version of the Cantril ladder.
			<ul class="questions">
				<li><span class="muted">cantril5</span> - Please see the ladder below. There are 8 steps on this ladder. How satisfied are you currently with your parenting/caregiving life on a scale of 1 to 8? where Step 1. is “very dissatisfied” and Step 8. is “extremely satisfied”?</li>
			</ul>
			<p>The graph above simply plots the percentage of respondents who self-identified as being on each of the eight rungs of the ladder.</p>

			<p>Note that this version of the Cantril ladder differs slightly from versions used elsewhere in that the wording has been simplified and we use an 8-point (1-8), rather than a 11-point (0-10) scale.</p>
		`,
	},

	{
		key: "cantril4",
		group: "parent",
		default_hVar: "grade",
		surveys: ["parent-resilience"],
		title: "Child Wellbeing Satisfaction",
		isNew: false,
		firstYear: 2019,
		classes: [
			{
				id: 8,
				label: "8 (best)",
				colour: "#388e3c",
			},
			{
				id: 7,
				label: "7",
				colour: "#43a047",
			},
			{
				id: 6,
				label: "6",
				colour: "#7cb342",
			},
			{
				id: 5,
				label: "5",
				colour: "#c0ca33",
			},
			{
				id: 4,
				label: "4",
				colour: "#fdd835",
			},
			{
				id: 3,
				label: "3",
				colour: "#ffb300",
			},
			{
				id: 2,
				label: "2",
				colour: "#fb8c00",
			},
			{
				id: 1,
				label: "1 (worst)",
				colour: "#f4511e",
			},
		],
		threshold: 5,
		classifier: Number,
		what_does_this_report_show: `
			<p>The question posed to parents/caregivers  was 'Please see the ladder below. There are 8 steps on this ladder. How satisfied are you with your child’s life at school on a scale of 1 to 8? where Step 1. is “very dissatisfied” and Step 8. is “extremely satisfied”?'. This question is an adapted version of the Cantril Ladder, a widely used survey tool for measing life satisfaction. The graph above shows the percentage of parents/caregivers  who identified themselves as being on each rung of the ladder.</p>
		`,
		purpose: `
			<p>To allow you to track levels of satisfaction with child wellbeing among your parents/caregivers .</p>
		`,
		ask_yourself: `
			<ul>
				<li>What is the overall distribution of satisfaction with child wellbeing?</li>
				<li>How does satisfaction with child wellbeing differ between genders, year levels and age groups?</li>
				<li>How has satisfaction with child wellbeing changed over time?</li>
				<li>What do you think is the reason for the variance in your data?</li>
			</ul>
		`,
		where_do_these_numbers_come_from: `
			<p>Respondents are simply grouped by their answer to the following question, which is an adapted version of the Cantril ladder.
			<ul class="questions">
				<li><span class="muted">cantril4</span> - There are 8 steps on this ladder. How satisfied are you with your child’s life at school on a scale of 1 to 8? where Step 1. is “very dissatisfied” and Step 8. is “extremely satisfied”?</li>
			</ul>
			<p>The graph above simply plots the percentage of respondents who self-identified as being on each of the eight rungs of the ladder.</p>

			<p>Note that this version of the Cantril ladder differs slightly from versions used elsewhere in that the wording has been simplified and we use an 8-point (1-8), rather than a 11-point (0-10) scale.</p>
		`,
	},

	{
		key: "cantril6",
		group: "parent",
		default_hVar: "grade",
		surveys: ["parent-resilience"],
		title: "School Satisfaction",
		isNew: false,
		firstYear: 2019,
		classes: [
			{
				id: 8,
				label: "8 (best)",
				colour: "#388e3c",
			},
			{
				id: 7,
				label: "7",
				colour: "#43a047",
			},
			{
				id: 6,
				label: "6",
				colour: "#7cb342",
			},
			{
				id: 5,
				label: "5",
				colour: "#c0ca33",
			},
			{
				id: 4,
				label: "4",
				colour: "#fdd835",
			},
			{
				id: 3,
				label: "3",
				colour: "#ffb300",
			},
			{
				id: 2,
				label: "2",
				colour: "#fb8c00",
			},
			{
				id: 1,
				label: "1 (worst)",
				colour: "#f4511e",
			},
		],
		threshold: 5,
		classifier: Number,
		what_does_this_report_show: `
			<p>The question posed to parents/caregivers  was 'On a scale of 1 to 8, how satisfied are you with THIS SCHOOL?'. This question is an adapted version of the Cantril Ladder, a widely used survey tool for measing life satisfaction. The graph above shows the percentage of parents/caregivers  who identified themselves as being on each rung of the ladder.</p>
		`,
		purpose: `
			<p>To allow you to track levels of overall school satisfaction among your parents/caregivers .</p>
		`,
		ask_yourself: `
			<ul>
				<li>What is the overall distribution of school satisfaction?</li>
				<li>How does school satisfaction differ between genders, year levels and age groups?</li>
				<li>How has school satisfaction changed over time?</li>
				<li>What do you think is the reason for the variance in your data?</li>
			</ul>
		`,
		where_do_these_numbers_come_from: `
			<p>Respondents are simply grouped by their answer to the following question, which is an adapted version of the Cantril ladder.
			<ul class="questions">
				<li><span class="muted">cantril6</span> - On a scale of 1 to 8, how satisfied are you with THIS SCHOOL?</li>
			</ul>
			<p>The graph above simply plots the percentage of respondents who self-identified as being on each of the eight rungs of the ladder.</p>

			<p>Note that this version of the Cantril ladder differs slightly from versions used elsewhere in that the wording has been simplified and we use an 8-point (1-8), rather than a 11-point (0-10) scale.</p>
		`,
	},

	{
		key: "healthy-mind",
		group: "parent",
		default_hVar: "grade",
		surveys: ["parent-resilience"],
		title: "Patient Health Questionnaire (4 item)",
		isNew: false,
		firstYear: 2019,
		classes: [
			{
				id: 4,
				label: "Normal",
				colour: "#199750",
			},
			{
				id: 3,
				label: "Mild",
				colour: "#66bc62",
			},
			{
				id: 2,
				label: "Moderate",
				colour: "#f36c42",
			},
			{
				id: 1,
				label: "Severe",
				colour: "#d62f26",
			},
		],
		threshold: 3,
		classifier: function (x) {
			if (x <= 2) {
				return 4;
			} else if (x <= 5) {
				return 3;
			} else if (x <= 8) {
				return 2;
			} else {
				return 1;
			}
		},
		what_does_this_report_show: `
			<p>This graph shows the percentage of parents/caregivers  who exhibit (to varying degrees) symptoms of anxiety and depression according to the healthy-mind - a validated, four-question screening tool.</p>
		`,
		purpose: `
			<p>To allow you to track the prevalence of anxiety and depression among your parents/caregivers .</p>
		`,
		ask_yourself: `
			<ul>
				<li>What percentage of our parents/caregivers  are likely to self-report symptoms of anxiety and depression?</li>
				<li>How does the prevalence of anxiety or depressive symptoms differ between year levels?</li>
				<li>How has the percentage of parents/caregivers  who experience anxiety and depression changed over time?</li>
				<li>What do you think is the reason for the variance in your data?</li>
			</ul>
		`,
		where_do_these_numbers_come_from: `
			<h6>Raw Score</h6>
			<p>The group into which a respondent is classified (one of 'Normal', 'Mild', 'Moderate' or 'Severe') is determined by their raw score on the shortened Patient Health Questionnaire scale, or healthy-mind, which consists of the following questions.
			<ul class="questions">
				<li>Over the last 2 weeks, how often have you been bothered by the following problems?
					<ul>
						<li><a href="../ph1/"><span class="muted">ph1</span> - Feeling nervous, anxious, or on edge.</a></li>
						<li><a href="../ph2/"><span class="muted">ph2</span> - Not being able to stop or control worrying.</a></li>
						<li><a href="../ph3/"><span class="muted">ph3</span> - Feeling down, depressed or hopeless.</a></li>
						<li><a href="../ph4/"><span class="muted">ph4</span> - Little interest or pleasure in doing things.</a></li>
					</ul>
				</li>
			</ul>
			<p>All four questions use the same Likert response scale. For the purposes of computing the overall anxiety and depression score, responses are encoded as follows:</p>
			<table>
				<tr><th>Response anchor</th><th>Numerical Encoding</th></tr>
				<tr><td>Not at all</td><td>0</td></tr>
				<tr><td>Several days</td><td>1</td></tr>
				<tr><td>More than half the days</td><td>2</td></tr>
				<tr><td>Nearly every day</td><td>3</td></tr>
			</table>
			<p>The healthy-mind score is then calculated by</p>
			<p>${math(
				`\\texttt{healthy-mind} := \\texttt{ph1} + \\texttt{ph2} + \\texttt{ph3} + \\texttt{ph4}`,
				true
			)}</p>
			<p>giving a score between ${math(0)} and ${math(12)}, inclusive.</p>

			<h6>Thresholds</h6>
			<p>Literature on the healthy-mind (see the article linked below) suggests classifying respondents' symptoms as 'Normal' if ${`0\\leq\\texttt{healthy-mind}\\leq2`}, 'Mild' if ${math(
			`3\\leq\\texttt{healthy-mind}\\leq5`
		)}, 'Moderate' if ${`6\\leq\\texttt{healthy-mind}\\leq8`} and 'Severe' if ${math(
			`9\\leq\\texttt{healthy-mind}\\leq12`
		)}. These groupings align the PHQ-2 with the results of longer instruments such as the GAD-7 or PHQ-9.

			<h6>Relevant Literature</h6>
			<ol class="literature">
				<li><a href="https://www.sciencedirect.com/science/article/pii/S0033318209708643">Kroenke, Kurt, et al. "An ultra-brief screening scale for anxiety and depression: the PHQ–4." <em>Psychosomatics</em> 50.6 (2009): 613-621.</a></li>
			</ol>
		`,
	},
	{
		key: "PHQ-2",
		group: "parent",
		default_hVar: "grade",
		surveys: ["parent-resilience"],
		title: "Patient Health Questionnaire (2 item)",
		isNew: false,
		firstYear: 2019,
		classes: [
			{
				id: 2,
				label: "Not Depressed",
				colour: "#66bc62",
			},
			{
				id: 1,
				label: "Depressed",
				colour: "#f36c42",
			},
		],
		threshold: 2,
		classifier: (x) => (x >= 3 ? 1 : 2),
		what_does_this_report_show: `
			<p>This graph shows the percentage of parents/caregivers  who exhibit symptoms of depression according to the PHQ-2 - a validated, two-question screening tool.</p>
		`,
		purpose: `
			<p>To allow you to track the prevalence of depressive symptoms among your parents/caregivers .</p>
		`,
		ask_yourself: `
			<ul>
				<li>What percentage of our parents/caregivers  self-report symptoms of depression?</li>
				<li>How does the prevalence of depressive symptoms differ between year levels?</li>
				<li>How has the percentage of parents/caregivers  who experience depression changed over time?</li>
				<li>What do you think is the reason for the variance in your data?</li>
			</ul>
		`,
		where_do_these_numbers_come_from: `
			<h6>Raw Score</h6>
			<p>The group into which a respondent is classified (either 'Depressed' or 'Not Depressed') is determined by their raw score on the shortened Patient Health Questionnaire scale, or PHQ-2, which consists of the following questions.
			<ul class="questions">
				<li>Over the last 2 weeks, how often have you been bothered by the following problems?
					<ul>
						<li><a href="../ph3/"><span class="muted">ph3</span> - Feeling down, depressed or hopeless.</a></li>
						<li><a href="../ph4/"><span class="muted">ph4</span> - Little interest or pleasure in doing things.</a></li>
					</ul>
				</li>
			</ul>
			<p>Both questions use the same Likert response scale. For the purposes of computing the overall depression score, responses are encoded as follows:</p>
			<table>
				<tr><th>Response anchor</th><th>Numerical Encoding</th></tr>
				<tr><td>Not at all</td><td>0</td></tr>
				<tr><td>Several days</td><td>1</td></tr>
				<tr><td>More than half the days</td><td>2</td></tr>
				<tr><td>Nearly every day</td><td>3</td></tr>
			</table>
			<p>The PHQ-2 score is then calculated by</p>
			<p>${math(`\\texttt{PHQ-2} := \\texttt{ph3} + \\texttt{ph4}`, true)}</p>
			<p>giving a score between ${math(0)} and ${math(6)}, inclusive.</p>

			<h6>Threshold</h6>
			<p>Literature on the PHQ-2 (see the articles linked below) suggests that a threshold of ${math(
				3
			)} provides acceptable sensitivity, specificity and discriminant validity to be used as a measure of depressive disorders. As such, we classify respondents as 'Depressed' if ${math(
			`\\texttt{PHQ-2}\\geq 3`
		)}, and 'Not Depressed' otherwise ${math(`\\texttt{PHQ-2}< 3`)}.</p>

			<h6>Relevant Literature</h6>
			<ol class="literature">
				<li><a href="https://www-cambridge-org.ezp.lib.unimelb.edu.au/core/services/aop-cambridge-core/content/view/A2083386E3287C1F982BCC82DCEA1CB6/S2056472400001265a.pdf/case_finding_and_screening_clinical_utility_of_the_patient_health_questionnaire_phq9_and_phq2_for_depression_in_primary_care_a_diagnostic_metaanalysis_of_40_studies.pdf">Mitchell, Alex J., et al. "Case finding and screening clinical utility of the Patient Health Questionnaire (PHQ-9 and PHQ-2) for depression in primary care: a diagnostic meta-analysis of 40 studies." <em>BJPsych open</em> 2.2 (2016): 127-138.</a></li>
				<li><a href="https://www.sciencedirect.com/science/article/abs/pii/S0022399904006361">Löwe, Bernd, Kurt Kroenke, and Kerstin Gräfe. "Detecting and monitoring depression with a two-item questionnaire (PHQ-2)." <em>Journal of psychosomatic research</em> 58.2 (2005): 163-171.</a></li>
				<li><a href="https://www.jstor.org/stable/3768417">Kroenke, Kurt, Robert L. Spitzer, and Janet BW Williams. "The Patient Health Questionnaire-2: validity of a two-item depression screener." <em>Medical care</em> (2003): 1284-1292.</a></li>
				<li><a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3100798/">Richardson, Laura P., et al. "Evaluation of the PHQ-2 as a brief screen for detecting major depression among adolescents." <em>Pediatrics</em> 125.5 (2010): e1097-e1103.</a></li>
				<li><a href="http://www.annfammed.org/content/8/4/348.long">Arroll, Bruce, et al. "Validation of PHQ-2 and PHQ-9 to screen for major depression in the primary care population." <em>The Annals of Family Medicine</em> 8.4 (2010): 348-353.</a></li>
				<li><a href="https://www.sciencedirect.com/science/article/pii/S0163834318303712?via%3Dihub">Staples, Lauren G., et al. "Psychometric properties and clinical utility of brief measures of depression, anxiety, and general distress: The PHQ-2, GAD-2, and K-6." <em>General hospital psychiatry</em> 56 (2019): 13-18.</a></li>
			</ol>
		`,
	},
	{
		key: "GAD-2",
		group: "parent",
		default_hVar: "grade",
		surveys: ["parent-resilience"],
		title: "Generalized Anxiety Disorder scale (2 item)",
		isNew: false,
		firstYear: 2019,
		classes: [
			{
				id: 2,
				label: "Not Anxious",
				colour: "#66bc62",
			},
			{
				id: 1,
				label: "Anxious",
				colour: "#f36c42",
			},
		],
		threshold: 2,
		classifier: (x) => (x >= 3 ? 1 : 2),
		what_does_this_report_show: `
			<p>This graph shows the percentage of parents/caregivers  who exhibit symptoms of anxiety according to the GAD-2 - a validated, two-question screening tool.</p>
		`,
		purpose: `
			<p>To allow you to track the prevalence of anxiety among your parents/caregivers .</p>
		`,
		ask_yourself: `
			<ul>
				<li>What percentage of our parents/caregivers  self-report symptoms of anxiety?</li>
				<li>How do levels of anxiety differ between age groups and year levels?</li>
				<li>How has the percentage of parents/caregivers  who are anxious changed over time?</li>
				<li>What do you think is the reason for the variance in your data?</li>
			</ul>
		`,
		where_do_these_numbers_come_from: `
			<h6>Raw Score</h6>
			<p>The group into which a respondent is classified (either 'Anxious' or 'Not Anxious') is determined by their raw score on the shortened General Anxiety Disorder scale, or GAD-2, which consists of the following questions.
			<ul class="questions">
				<li>Over the last 2 weeks, how often have you been bothered by the following problems?
					<ul>
						<li><a href="../ph1/"><span class="muted">ph1</span> - Feeling nervous, anxious, or on edge.</a></li>
						<li><a href="../ph2"><span class="muted">ph2</span> - Not being able to stop or control worrying.</a></li>
					</ul>
				</li>
			</ul>
			<p>Both questions use the same Likert response scale. For the purposes of computing the overall anxiety score, responses are encoded as follows:</p>
			<table>
				<tr><th>Response anchor</th><th>Numerical Encoding</th></tr>
				<tr><td>Not at all</td><td>0</td></tr>
				<tr><td>Several days</td><td>1</td></tr>
				<tr><td>More than half the days</td><td>2</td></tr>
				<tr><td>Nearly every day</td><td>3</td></tr>
			</table>
			<p>The GAD-2 score is then calculated by</p>
			<p>${math(`\\texttt{GAD-2} := \\texttt{ph1} + \\texttt{ph2}`, true)}</p>
			<p>giving a score between ${math(0)} and ${math(6)}, inclusive.</p>

			<h6>Threshold</h6>
			<p>Literature on the GAD-2 (see the articles linked below) suggests that a threshold of ${math(
				3
			)} provides acceptable sensitivity, specificity and discriminant validity to be used as a measure of anxiety disorders and general stress. As such, we classify respondents as 'Anxious' if ${math(
			`\\texttt{GAD-2}\\geq 3`
		)}, and 'Not Anxious' otherwise ${math(`\\texttt{GAD-2}< 3`)}.</p>

			<h6>Relevant Literature</h6>
			<ol class="literature">
				<li><a href="https://s3.amazonaws.com/academia.edu.documents/41377550/1-s2.0-S0163834315002406-main.pdf?response-content-disposition=inline%3B%20filename%3DScreening_for_anxiety_disorders_with_the.pdf&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIATUSBJ6BALQOGIJB4%2F20200428%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20200428T231700Z&X-Amz-Expires=3600&X-Amz-Security-Token=IQoJb3JpZ2luX2VjELf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIE1FK6RxfDigp%2BdfMasvjgaGuw2KEN2bJKKwVOWXzBADAiEA%2F0pyV1hXhf1MSDdYgvZm2NoDc6GiFuRf7lTJPiRsrsUqvQMI4P%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAAGgwyNTAzMTg4MTEyMDAiDBBFukGo%2BIYb9cKRFCqRA3lRioiCa1q7OYYucTWmC7exOFwGrmxd680wDCGMzLL0hL02SCVcpRVJbgWTGkqK0RM0Fnc3YLvp8Ql8kpeJncVpmIs8vLv4NkeoG38Mlvn%2Bj%2BCrdhjLN9QiF8voDoI3hcKHHwnQY%2F2UMS%2FZpSfsWgVUO%2B51ThUvVgP6fO3EcVMg6T7eizdielfcpeX5fWDaDPBClJQuM5ZDmN1SYIRAeX2FMpTauKyT9tx%2F30pqQLj2mM1qbWdjoDTCzKX7GSoC4URQeYEBgD7jxauDklexacAGSIs2tsEK9Ein9Qv%2F7O%2FVFqj79IJtP575UrsUe19wD6LeWKJ6f7VV7MtQiUTD%2FL9a7WUvobgWkDUHI2oNcHgM4oPfH08EgGeKlEStVSDddY4u3g2k6H9R2DR3muX8dQiALVcIWaN2b0wL5A%2B4zp5NnOfKNbXPuV9dPOnGelZElCoVfkPgaBWtckUOhA5KIEO6mXk1BHnStrIHN1umQnZhNQ9TsWi9cGI9%2BsvKPxw6SVLaoqZwilqQNStMnLjL56ePMNXqovUFOusBOu9PYb8I56ugePDCxA%2FVDtTEwt49JwdECHFVjQYpkimD9enu2e%2BSfUIznZlLpF%2FdYHJ65FNrGF32kN0Tif4zWgdnEAbjC26tzitvGweYczOy%2FvE0gRw7znBLKKf5vsEIDDr7euj9HhoWq9eE0gc9PosRboycFoojiEpAL0pRE3N8ZNTqEOJrOpNNeAQ1ZQQKvPp%2BX99te781K8HD%2FJ79KoeyhywFG3HpB%2BpefNg%2BmQ3d1VlazsDgeNk0IVO8lWlkjadV8B4NGSJbgCY6coZh1YQzRb6pqqomm%2Fumt9pUPJfpOCn18Ne2YfT1tA%3D%3D&X-Amz-SignedHeaders=host&X-Amz-Signature=44f443f777c4972aacf83379d1189f868468bfa3320e9fdcffed49b23a21842a">Plummer, Faye, et al. "Screening for anxiety disorders with the GAD-7 and GAD-2: a systematic review and diagnostic metaanalysis." <em>General hospital psychiatry</em> 39 (2016): 24-31.</a></li>
				<li><a href="https://www.sciencedirect.com/science/article/pii/S0163834318303712?via%3Dihub">Staples, Lauren G., et al. "Psychometric properties and clinical utility of brief measures of depression, anxiety, and general distress: The PHQ-2, GAD-2, and K-6." <em>General hospital psychiatry</em> 56 (2019): 13-18.</a></li>
			</ol>
		`,
	},
];

export default stackedbars;
