import {neverland as $, html} from 'neverland';

const Header = $(function(auth, setAuth, theme) {

	function Button(spec) {
		
		let { path, icon, text } = spec;

		if (typeof(path) == "function") {
			path = path();
		}
		
		let	thispage = ( path == '/' ? path == window.location.pathname : window.location.pathname.split('/').filter(d => d)[0] == path.split('/').filter(d => d)[0]);

		return html`<a href="${path}" class="${thispage ? 'thispage' : ''}"><i class="fal ${icon}"></i>&ensp;${text}</a>`;
	
	}

	let buttons = [
		{
			path: '/',
			icon: 'fa-list',
			text: 'All Surveys'
		},
		{
			path: () => {
				let path = '/data/';
				
				if (theme.surveys) {
					let permittedSurveys = theme.surveys.filter(d => auth.groups[d.permission]);
					if (permittedSurveys.length > 0) {
						path = `/data/${permittedSurveys[0].id}/`;
					}
				}

				return path;
			},
			icon: 'fa-chart-bar',
			text: 'Reports'
		}
	];

	if (auth.groups.enableActionPlans) {
		buttons.push({
			path: '/plan/action-plans/',
			icon: 'fa-route',
			text: 'Plans'
		})
	}

	async function logOut() {
		
		await auth.app.currentUser.logOut();
		window.location.pathname = "/";
		
	}

	return html`
		<header>
			<span>
				${buttons.map(d => Button(d))}
			</span>
			<span>
				<span>${auth ? auth.clientGroupName : ''}</span>
				<button id="logout" onclick=${logOut}><i class="fal fa-sign-out-alt"></i>&ensp;Logout</button>
			</span>
		</header>
	`;

});

export default Header;