import {neverland as $, render, html, useState} from 'neverland';

const StyleSheet = function(theme) {

	if (theme === null) {

		return html``;
	
	} else {

		let css = '',
			attributes = [
				{ css: 'sans-serif-stack', js: 'sansSerifStack'},
				{ css: 'serif-stack', js: 'serifStack'},
				{ css: 'monospace-stack', js: 'monospaceStack'},
				{ css: 'colour-main', js: 'colourMain'},
				{ css: 'colour-main-translucent', js: 'colourMainTranslucent'},
				{ css: 'colour-main-tint', js: 'colourMainTint'},
				{ css: 'image-background', js: 'imageBackground'},
				{ css: 'background-size', js: 'backgroundSize'},
				{ css: 'background-repeat', js: 'backgroundRepeat'},
				{ css: 'background-position', js: 'backgroundPosition'},
				{ css: 'offering-block-display', js: 'offeringBlockDisplay'},
				{ css: 'colour-login-input', js: 'colourLoginInput'},
				{ css: 'colour-login-error', js: 'colourLoginError'},
				{ css: 'colour-progress', js: 'colourProgress'},
			];

		for (let a of attributes) {
			if (theme.hasOwnProperty(a.js)) {
				css = css + `--${a.css}: ${theme[a.js]};`
			}
		}

		return {html: `
			<style id="css-parameters">
				:root {${css}}
				${theme.customCssReporting ? theme.customCssReporting : ''}
			</style>
		`};

	}

};

export default StyleSheet;